<template>
  <section
    v-if="variants.v0 === variant"
    class="section-services-content pt-5"
    :style="`background-color: var(--white)`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-column align-items-center justify-content-center w-100 pb-5">
            <h1 class="heading-medium">{{ vars.titleText }}</h1>
            <div class="margin-top margin-small">
              <div class="max-width-large">
                <p class="text-size-tiny text-weight-light text-center">
                  {{ vars.descriptionText }}
                </p>
              </div>
            </div>
          </div>
          <div class="margin-top margin-xlarge">
            <div class="w-dyn-list">
              <Swiper
                :modules="[SwiperNavigation]"
                :slides-per-view="3"
                :space-between="30"
                :loop="true"
                effect="fade"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                }"
                :breakpoints="{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1080: {
                    slidesPerView: 2,
                    spaceBetween: 42,
                  },
                  1336: {
                    slidesPerView: 3,
                    spaceBetween: 42,
                  },
                }"
                class="slider"
                @swiper="onSwiper"
              >
                <SwiperSlide
                  v-for="(team, index) in teams"
                  :key="`team-${index}`"
                  role="listitem"
                  class="team-member-item w-dyn-item"
                >
                  <a
                    id="w-node-ee1a785f-164b-ccdd-3304-7868a8ac7fb4-89606d85"
                    data-w-id="ee1a785f-164b-ccdd-3304-7868a8ac7fb4"
                    href="#"
                    class="link-content-block team-member w-inline-block"
                  >
                    <div class="image-wrapper project">
                      <img
                        alt=""
                        loading="lazy"
                        style="transform: translate3d(0, 0, 0) scale3d(1, 1, 1)"
                        :src="team.photo"
                        class="cover-image project"
                      />
                      <div
                        style="display: block; transform: translate3d(100%, 0, 0) scale3d(1, 1, 1)"
                        class="overlay-animation"
                      ></div>
                    </div>
                    <div class="margin-top margin-medium" style="margin-bottom: 20px !important">
                      <h3 style="opacity: 1" class="heading-xsmall">
                        {{ team.fullname }}
                      </h3>
                    </div>
                    <p class="text-size-small mb-0">{{ team.email }}</p>
                    <p class="text-size-small">
                      {{ team.tel }}
                    </p>
                  </a>
                </SwiperSlide>
                <div class="controls d-xl-none">
                  <div
                    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                    class="slide-left-arrow w-slider-arrow-left"
                    @click="() => swiper.slidePrev()"
                  >
                    <img
                      src="/cdn/theme2/images/left-arrow.svg"
                      loading="lazy"
                      alt="Left Arrow - Inner Webflow Template"
                      class="arrow-icon slider-arrow-transform"
                    />
                    <div class="slide-circle slider-circle-transform"></div>
                  </div>
                  <div
                    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                    class="slide-right-arrow w-slider-arrow-right"
                    @click="() => swiper.slideNext()"
                  >
                    <img
                      src="/cdn/theme2/images/right-arrow.svg"
                      loading="lazy"
                      alt="Right Arrow - Inner Webflow Template"
                      class="arrow-icon slider-arrow-transform"
                    />
                    <div class="slide-circle right"></div>
                  </div>
                  <div class="slide-nav w-slider-nav w-round"></div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: var(--c-${vars.textColor})`"
  >
    <div class="tw-mb-8 tw-flex tw-w-full tw-flex-col md:tw-flex-row">
      <!-- Desktop Versiyon: Hover efekti eklenmiş image -->
      <div class="tw-order-1 tw-mb-4 md:tw-order-2 md:tw-mb-0 md:tw-w-1/2">
        <div class="tw-group tw-relative tw-overflow-hidden" style="border-radius: var(--rounded)">
          <LibraryImage
            :src="vars.photoImage"
            :h-ratio="1"
            :w-ratio="1.5"
            class="tw-z-0 tw-h-auto tw-w-full tw-duration-500 group-hover:tw-scale-110"
            style="border-radius: var(--rounded)"
          />
          <div
            class="tw-absolute tw-inset-0 tw-bg-[var(--c-secondary)] tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-30"
            style="border-radius: var(--rounded)"
          ></div>
        </div>
      </div>

      <div class="tw-order-2 tw-flex tw-flex-col tw-justify-center tw-pr-4 md:tw-order-1 md:tw-w-1/2">
        <h2 class="heading-medium tw-mb-4" :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h2>
        <p v-for="({ description }, index) in descriptions" :key="`team-description-${index}`" class="tw-max-w-2xl">
          {{ description }}
        </p>
      </div>
    </div>

    <!-- Mobil Versiyon (Swiper) -->
    <div class="tw-relative tw-w-full">
      <Swiper
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        @slideChange="onSlideChange"
        effect="fade"
        @swiper="onSwiper"
        :clickable="true"
        :breakpoints="{
          320: { slidesPerView: 1, spaceBetween: 20 },
          768: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: 3, spaceBetween: 20 },
          1280: { slidesPerView: 4, spaceBetween: 20 },
        }"
        class="tw-w-full"
      >
        <SwiperSlide
          v-for="(member, index) in members"
          :key="`ourteam-swiper-member-${index}`"
          class="!tw-flex !tw-h-auto tw-max-w-none tw-items-center tw-justify-center"
        >
          <div
            class="tw-group tw-relative tw-flex tw-h-full tw-w-full tw-cursor-default tw-flex-col tw-overflow-hidden tw-text-start tw-transition-all tw-duration-300"
          >
            <!-- Fotoğraf Alanı: Hover efekti eklenmiş -->
            <div class="tw-relative">
              <div
                class="tw-group tw-relative tw-overflow-hidden tw-p-4 tw-duration-500"
                style="border-radius: var(--rounded)"
              >
                <LibraryImage
                  :src="member.photo"
                  :h-ratio="1.5"
                  :w-ratio="1"
                  class="tw-z-0 tw-duration-500 group-hover:tw-scale-110"
                  style="border-radius: var(--rounded)"
                />
                <div
                  class="tw-absolute tw-inset-0 tw-bg-[var(--c-secondary)] tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-30"
                  style="border-radius: var(--rounded)"
                ></div>
              </div>
            </div>
            <!-- Kart Bilgi Alanı -->
            <div class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-start tw-gap-4 tw-p-6 tw-text-center">
              <h4 class="n-title-primary tw-mb-2 tw-break-words" :style="`color: var(--c-${vars.titleColor});`">
                {{ member.name }} {{ member.surname }}
              </h4>
              <small>{{ member.role }}</small>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div class="tw-mt-10">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v7"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'Team',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v0: '0',
        v2: 'v2',
        canPaginateBack: false,
        canPaginateNext: true,
      },
      teams: [] as { [key: string]: string | number | null }[],
      swiper: {},
    };
  },

  created() {
    this.teams = this.groupedVariables.teams;
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    members(): any[] {
      return this.groupedVariables.members || [];
    },
  },

  mounted() {
    this.onSlideChange();
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
.cover-image {
  height: auto;
  min-height: 400px;
  max-height: 400px;
}

.image-wrapper {
  width: 100%;
}
</style>
