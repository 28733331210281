<template>
  <section
    v-if="variants.v1 === variant"
    class="section-home-blog"
    :class="isSmall ? '' : 'tw-pt-24'"
    :style="`background-color: var(--white) !important`"
  >
    <div :class="isSmall ? '' : 'page-padding'">
      <div class="container-large">
        <div class="" :class="[vars.styleHtml, isSmall ? '' : 'padding-xhuge padding-vertical']">
          <div class="tw-flex tw-w-full tw-flex-row tw-justify-center">
            <h2 :class="isSmall ? 'heading-medium tw-text-center tw-text-3xl' : 'heading-medium'">
              {{ vars.titleText }}
            </h2>
          </div>
          <div class="tw-mt-10">
            <Swiper
              v-if="guides && guides.length > 0"
              :modules="[SwiperNavigation]"
              :slides-per-view="1"
              :space-between="81"
              effect="fade"
              :loop="true"
              :wrapper-class="wrapperClass"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :breakpoints="breakpoints"
              @swiper="onSwiper"
            >
              <SwiperSlide
                v-for="(guide, index) in guides"
                :key="`guide-${index}`"
                role="listitem"
                class="w-dyn-item d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  v-if="guide"
                  data-w-id="f5198688-baf0-dae4-22dd-e42c6e60a76a"
                  class="link-content-block blog w-inline-block justify-content-between"
                >
                  <div class="margin-bottom">
                    <div class="tw-h-fit lg:tw-h-[400px]">
                      <nuxt-link :to="guide.link" :external="true">
                        <NuxtImg
                          alt=""
                          loading="lazy"
                          class="tw-object-contain"
                          style="
                            -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                            width: 100%;
                            height: 100%;
                          "
                          quality="60"
                          :src="guide.image"
                        />
                      </nuxt-link>
                    </div>
                    <div class="blog-content mt-3">
                      <div class="margin-bottom margin-small">
                        <h3 style="opacity: 1" class="heading-xsmall">
                          {{ guide.title }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <nuxt-link
                    :to="guide.link"
                    data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                    class="button outline w-inline-block n-cursor-pointer"
                    style="width: 140px"
                    :external="true"
                  >
                    <div>Get this guide</div>
                    <div
                      style="
                        -webkit-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -moz-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                          skew(0, 0);
                        transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
                      "
                      class="hover-underline"
                    ></div>
                  </nuxt-link>
                </div>
              </SwiperSlide>
              <div
                class="controls"
                :class="(guides && guides.length > 4) || (isSmall && guides && guides.length > 1) ? '' : 'd-xl-none'"
              >
                <div
                  data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                  class="slide-left-arrow w-slider-arrow-left"
                  @click="() => swiper.slidePrev()"
                >
                  <img
                    src="/cdn/theme2/images/left-arrow.svg"
                    loading="lazy"
                    alt="Left Arrow - Inner Webflow Template"
                    class="arrow-icon slider-arrow-transform"
                  />
                  <div class="slide-circle slider-circle-transform"></div>
                </div>
                <div
                  data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                  class="slide-right-arrow w-slider-arrow-right"
                  @click="() => swiper.slideNext()"
                >
                  <img
                    src="/cdn/theme2/images/right-arrow.svg"
                    loading="lazy"
                    alt="Right Arrow - Inner Webflow Template"
                    class="arrow-icon slider-arrow-transform"
                  />
                  <div class="slide-circle right"></div>
                </div>
                <div class="slide-nav w-slider-nav w-round"></div>
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    v-else-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-12">
      <!-- Başlık -->
      <div class="tw-justify-left tw-flex tw-w-full tw-flex-row">
        <h2 :class="isSmall ? 'heading-medium tw-text-center tw-text-3xl' : 'heading-medium'">
          {{ vars.titleText }}
        </h2>
      </div>

      <div class="tw-flex tw-w-full tw-flex-col tw-gap-12">
        <Swiper
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :pagination="false"
          :slides-per-view="3"
          :space-between="24"
          :clickable="true"
          :breakpoints="{
            320: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 24 },
            1024: { slidesPerView: 3, spaceBetween: 24 },
            1336: { slidesPerView: 4, spaceBetween: 24 },
          }"
          class="tw-w-full"
        >
          <SwiperSlide v-for="(guide, index) in guides" :key="`ourguide-guide-${index}`" class="!tw-flex !tw-h-auto">
            <div
              class="n-card tw-group tw-relative tw-flex tw-min-h-[600px] tw-w-full tw-cursor-pointer tw-flex-col tw-overflow-hidden tw-rounded-[var(--c-rounded)] tw-p-2 tw-text-start tw-transition-all tw-duration-500 xl:tw-h-auto xl:tw-min-h-[300px]"
              style="border-radius: var(--rounded)"
            >
              <div class="tw-relative">
                <div class="tw-relative">
                  <library-image
                    v-if="guide.image"
                    :src="guide.image"
                    :is-ratio-free="true"
                    class="tw-h-full tw-w-full tw-object-cover"
                    style="border-radius: var(--rounded)"
                  />
                </div>
                <!-- Hover Overlay -->
                <div
                  style="border-radius: var(--rounded)"
                  class="tw-absolute tw-inset-0 tw-bg-[#f46f2c] tw-opacity-0 tw-transition-all tw-duration-300 tw-ease-in-out group-hover:tw-opacity-30"
                ></div>
              </div>

              <div class="tw-flex tw-h-full tw-flex-col tw-items-start tw-justify-end tw-py-2 tw-text-left">
                <h4 class="tw-mb-2 tw-line-clamp-2 tw-h-20 tw-overflow-hidden tw-text-[--c-primary]">
                  {{ guide.title }}
                </h4>
                <nuxt-link
                  :to="guide.button_link ? guide.button_link : ''"
                  :external="true"
                  target="_blank"
                  class="n-link tw-flex tw-items-center tw-text-[var(--c-secondary)]"
                >
                  VIEW THIS GUIDE
                  <svg
                    class="tw-ml-6"
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.59155 4.41479L1.29163 7.71471L0.466797 6.88987L3.3543 4.00237L0.466797 1.11487L1.29163 0.290039L4.59155 3.58996C4.7009 3.69935 4.76234 3.84769 4.76234 4.00237C4.76234 4.15705 4.7009 4.3054 4.59155 4.41479Z"
                      fill="#F46F2C"
                    />
                  </svg>
                </nuxt-link>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div>
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v7"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'GuidesBoxTheme2',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },

      canPaginateBack: false,
      canPaginateNext: true,
      swiper: null,
    };
  },

  computed: {
    guides() {
      return this.groupedVariables.guide;
    },

    wrapperClass() {
      return this.guides && this.guides.length < 4 ? 'swiper-wrapper swiper-wrapper-centered' : 'swiper-wrapper';
    },

    isSmall() {
      return this.vars.isSmallNumber;
    },

    breakpoints() {
      if (this.isSmall) {
        return {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        };
      }
      return {
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        560: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        1080: {
          slidesPerView: 3,
          spaceBetween: 64,
        },
        1336: {
          slidesPerView: 4,
          spaceBetween: 72,
        },
      };
    },
  },

  mounted() {
    this.onSlideChange();
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
.blog-content {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-slide {
  height: unset !important;
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}

.link-content-block.blog {
  flex-direction: column !important;
  align-items: flex-start !important;
}

::v-deep(.swiper-wrapper-centered) {
  display: flex !important;
  justify-content: center !important;
}

@media screen and (max-width: 991px) {
  ::v-deep(.swiper-wrapper-centered) {
    justify-content: left !important;
  }
}
</style>
