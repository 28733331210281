<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-flex tw-flex-col tw-gap-12">
      <div class="tw-flex tw-flex-wrap tw-justify-start tw-text-start lg:tw-gap-4">
        <h1 :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h1>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-6 lg:tw-flex-row lg:tw-gap-16">
        <MotionGroup
          preset="popVisibleOnce"
          :duration="800"
          is="div"
          v-for="(reason, index) in reasons"
          :key="`whysection-reason-${index}`"
          class="tw-relative tw-flex tw-w-full tw-max-w-xl tw-flex-col tw-items-start tw-gap-x-4 tw-gap-y-4 tw-overflow-hidden tw-p-2 tw-text-left tw-transition-all tw-duration-500 hover:tw-translate-y-[-5px]"
        >
          <div>
            <h4 :style="`color: var(--c-${vars.titleColor})`">
              {{ reason.title }}
            </h4>

            <p class="tw-line-clamp-[10]" :style="`color: var(--c-${vars.textColor}) `">
              {{ reason.description }}
            </p>
          </div>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { MotionGroup } from '#components';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhySection',
  mixins: [ComponentMixin],

  data() {
    return {};
  },

  computed: {
    reasons() {
      return this.groupedVariables.reasons;
    },
  },
});
</script>
