<template>
  <div>
    <div
      v-if="variants.home === variant"
      class="section-home-projects vh-100"
      style="background-color: var(--seashell)"
    >
      <div id="wrapper">
        <div class="video-content">
          <h2
            style="color: var(--white); font-weight: 200; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)"
            class="heading-large"
            v-html="overlayTitle"
          ></h2>
          <div
            class="button-flex-wrapper mt-4"
            :class="buttonsLayout === 2 ? 'tw-flex tw-flex-col tw-items-start tw-gap-y-2 md:tw-flex-row' : ''"
          >
            <nuxt-link
              id="w-node-aba3c3ea-3ff0-3543-f79c-42814ece1460-89606db2"
              :external="true"
              :to="variables.mainButtonUrl"
              class="button secondary w-inline-block"
              style="background-color: var(--white); color: var(--dark-brown)"
            >
              <div class="button-label">
                {{ variables.mainButtonText }}
              </div>
              <div style="height: 0%" class="overlay"></div>
            </nuxt-link>
            <nuxt-link
              data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
              :external="true"
              :to="variables.secondButtonUrl"
              class="button outline w-inline-block"
              style="color: var(--white)"
            >
              <div>{{ variables.secondButtonText }}</div>
              <div style="background-color: var(--white)" class="hover-underline"></div>
            </nuxt-link>
          </div>
        </div>
        <div class="video-wrap">
          <video id="video" muted :loop="true" playsinline preload="none" :src="variables.video">
            <source type="video/mp4" :video-hd="variables.video" :video-sd="variables.video" />
          </video>
        </div>
      </div>
    </div>

    <section v-else-if="variants.v2 === variant" class="tw-h-[100svh]">
      <video
        v-if="isMobileDevice && vars.mobileBackgroundVideo"
        :src="vars.mobileBackgroundVideo"
        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
        autoplay
        loop
        muted
        playsinline
      ></video>

      <video
        v-else-if="vars.backgroundVideo"
        :src="vars.backgroundVideo"
        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
        autoplay
        loop
        muted
        playsinline
      ></video>
      <NuxtImg
        :lazy="true"
        placeholder="blur"
        v-if="vars.mobileBackgroundImage && isMobileDevice"
        :src="vars.mobileBackgroundImage"
        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      />
      <NuxtImg
        :lazy="true"
        placeholder="blur"
        v-else-if="vars.backgroundImage"
        :src="vars.backgroundImage"
        class="tw-absolute tw-left-0 tw-top-0 tw-h-full tw-w-full tw-object-cover"
      />
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-25"></div>
      <div class="tw-relative tw-z-10 tw-flex tw-h-full tw-w-full tw-items-end tw-justify-start">
        <div
          class="tw-z-100 tw-my-4 tw-mb-8 tw-flex tw-max-w-[72rem] tw-flex-col tw-gap-2 tw-px-3 tw-py-2 tw-text-start md:tw-px-14 md:tw-py-10 xl:tw-w-[90%] xl:tw-gap-6 xl:tw-px-10"
        >
          <h1 class="tw-text-white">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusantium, sequi?</h1>
          <div
            class="tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-gap-x-6 tw-gap-y-3 min-[480px]:tw-flex-row min-[480px]:tw-justify-start md:tw-mt-0 lg:tw-gap-8"
          >
            <div class="tw-mt-4 tw-flex tw-w-full tw-flex-wrap tw-justify-start tw-gap-3 tw-gap-x-6">
              <nuxt-link
                v-for="(button, index) in buttons"
                :key="`button-${index}`"
                :class="[
                  buttons.length >= 2 ? 'tw-w-full md:tw-w-auto' : 'tw-w-auto',
                  `n-${button.button_type}`,
                  '!tw-justify-start tw-uppercase',
                ]"
                :to="button.button_link"
              >
                {{ button.button_label }}
                <span class="btn-arrow"
                  ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'HomeIntro',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        home: 'HomeIntro2',
        v2: 'v2',
      },
    };
  },

  computed: {
    overlayTitle() {
      return getVariable(this.component, 'overlay_title-html');
    },

    buttonsLayout() {
      return this.vars.mobileButtonLayoutNumber;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },

    variables() {
      return {
        video: getVariable(this.component, 'background-video'),
        mainButtonText: getVariable(this.component, 'main_button_title-text'),
        mainButtonUrl: getVariable(this.component, 'main_button_link-url'),
        secondButtonText: getVariable(this.component, 'second_button_title-text'),
        secondButtonUrl: getVariable(this.component, 'secondary_button-url'),
      };
    },
  },

  mounted() {
    const myVideo = document.getElementById('video');
    if (myVideo) {
      (myVideo as HTMLVideoElement).play();
    }
  },
});
</script>

<style scoped>
#wrapper {
  position: relative;
}
#wrapper video {
  position: relative;
  z-index: 100;
}
#wrapper .video-content {
  position: absolute;
  display: block;
  z-index: 150;
  left: 3%;
  bottom: 6%;
}

.video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: no-repeat center center/cover;
}
video {
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  background: no-repeat center center/cover;
}
</style>
