<template>
  <section v-if="variant === variants.center" class="section-services-content pt-5" :style="`background-color: white`">
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom tw-pb-1">
          <div class="d-flex flex-row justify-content-center w-100">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variant === variants.v2"
    class="n-section-primary tw-relative tw-min-h-[80vh] tw-bg-cover tw-bg-center"
    :style="`background-image: url(${isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage});`"
  >
    <div
      class="tw-absolute tw-inset-0 tw-bg-black"
      :style="`opacity: ${vars.opacityAmountNumber ? vars.opacityAmountNumber : 30}%`"
    ></div>

    <div
      class="tw-items-left xl:tw-items-left tw-relative tw-z-10 tw-mt-auto tw-flex tw-h-full tw-w-full tw-flex-col tw-justify-end tw-gap-8"
    >
      <h1 :style="`color: var(--c-${vars.titleColor});`">{{ vars.titleText }}</h1>

      <p v-if="vars.descriptionText" class="tw-text-white">{{ vars.descriptionText }}</p>

      <div v-if="buttons" class="tw-justify-left tw-flex tw-flex-wrap tw-gap-2">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-${index}`"
          :class="[
            buttons.length >= 2 ? 'tw-w-full md:tw-w-auto' : 'tw-w-auto',
            `n-${button.button_type}`,
            'tw-uppercase',
          ]"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow">
            <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'SectionIntro',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    variant() {
      return useVariant(this.component);
    },

    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
