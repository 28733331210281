<template>
  <section
    class="n-section-primary tw-bg-cover tw-bg-center"
    :style="`background-color: var(--c-${vars.backgroundColor});`"
  >
    <div class="tw-z-10 tw-flex tw-flex-col tw-items-center tw-gap-4 tw-text-center">
      <div class="tw-flex tw-flex-wrap tw-justify-center tw-text-center lg:tw-gap-4">
        <h1 class="heading-medium tw-text-center" :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h1>
      </div>

      <div v-if="descriptions">
        <p
          v-for="({ description }, index) in descriptions"
          :key="`takeaction-description-${index}`"
          class="tw-max-w-2xl"
          :style="`color: var(--c-${vars.textColor});`"
        >
          {{ description }}
        </p>
      </div>
      <div class="tw-mt-6 tw-flex tw-flex-wrap tw-justify-start tw-gap-3">
        <nuxt-link
          v-for="(button, index) in buttons"
          :key="`button-${index}`"
          :class="[
            buttons.length >= 2 ? 'tw-w-full md:tw-w-auto' : 'tw-w-auto',
            `n-${button.button_type}`,
            'tw-uppercase',
          ]"
          :to="button.button_link"
        >
          {{ button.button_label }}
          <span class="btn-arrow"
            ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                fill="white"
              />
            </svg>
          </span>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TakeAction',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>
