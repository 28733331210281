<template>
  <section
    v-if="variants.v1 === variant"
    :style="`background-color: var(--c-${vars.backgroundColor});`"
    class="tw-pt-12 xl:tw-pt-24"
  >
    <h1 v-if="vars.titleText" class="tw-mb-12 tw-w-full tw-pt-32 tw-text-start tw-text-[var(--c-primary)] lg:tw-mb-20">
      {{ vars.titleText }}
    </h1>

    <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-4">
      <div
        class="tw-col-span-12"
        :class="{
          'lg:tw-col-span-7 xl:tw-col-span-8': sidebarComponents && sidebarComponents.length > 0,
        }"
      >
        <div v-if="vars.isSearchBoxEnabledBool" class="blogs-page tw-mb-10 tw-w-full">
          <search-box
            v-model="keyword"
            :search-result-list="[]"
            :placeholder="searchBoxPlaceholder"
            :search-method="onSearched"
            version="v3"
          />
        </div>

        <div v-if="isBlogsLoading" style="height: 60dvh">
          <loader :loader-color="colors.brown" />
        </div>
        <div v-else-if="blogs && blogs.length === 0">
          <lazy-library-not-found :title="notFound.title" :description="notFound.description" />
        </div>

        <div v-else-if="!isBlogsLoading" class="tw-grid tw-grid-cols-12 tw-gap-y-6 md:tw-gap-x-10">
          <template v-for="(blog, index) in blogs" :key="`blog-${index}`">
            <div
              class="tw-col-span-12"
              :class="{
                'md:tw-col-span-6 lg:tw-col-span-12 xl:tw-col-span-6':
                  sidebarComponents && sidebarComponents.length > 0,
                'md:tw-col-span-6 lg:tw-col-span-4': !sidebarComponents || sidebarComponents.length === 0,
              }"
            >
              <lazy-theme2-blogs-blog-card :blog="blog" :index="index" version="v1" />
            </div>

            <div
              v-if="vars.customCardNumber && (index + 1) % vars.customCardNumber === 0"
              class="tw-col-span-12 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-8 tw-p-10"
              :style="`border-radius: var(--rounded); background-color: var(--c-${vars.cardBackgroundColor})`"
              :class="{
                'md:tw-col-span-6 lg:tw-col-span-12 xl:tw-col-span-6':
                  sidebarComponents && sidebarComponents.length > 0,
                'md:tw-col-span-6 lg:tw-col-span-4': !sidebarComponents || sidebarComponents.length === 0,
              }"
            >
              <h4 :style="`color: var(--c-${vars.cardTitleColor}`" class="tw-w-full">{{ vars.cardTitleText }}</h4>
              <div class="tw-flex tw-flex-col tw-gap-6">
                <p>{{ vars.cardDescriptionText }}</p>
                <nuxt-link :class="`n-${vars.cardButtonTypeText} tw-self-start`" :to="vars.cardButtonLinkUrl">
                  {{ vars.cardButtonLabelText }}
                  <span class="btn-arrow"
                    ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </nuxt-link>
              </div>
            </div>
          </template>
        </div>
        <div v-if="isPaginationVisible">
          <lazy-library-blogs-pagination
            :component="component"
            version="v3"
            pagination-active-bg="var(--c-primary);"
            pagination-bg="var(--c-input);"
            :is-pagination-arrow="true"
          />
        </div>
        <div v-if="!vars.isPaginationEnabledBool" class="tw-flex tw-w-full tw-items-center tw-justify-center tw-pt-4">
          <nuxt-link class="n-primary" :to="ROUTES.BLOGS" :external="true">View All Blogs</nuxt-link>
        </div>
      </div>
      <div
        v-if="sidebarComponents && sidebarComponents.length > 0"
        class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4"
      >
        <div class="sidebar tw-flex tw-flex-col tw-gap-6">
          <theme2-available-components-for-sidebar
            key="blogs-page-sidebar"
            key-prefix="blogs-page-sidebar"
            :specific-components="sidebarComponents"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import type { Component } from '~/types';
import Loader from '~/components/common/Loader.vue';
import NotFound from '~/components/theme4/base/NotFound.vue';
import indexGlobal from '~/mixins/index.global';
import { BlogsMixin } from '~/units/blogs/mixins/blogs.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import SearchBox from '~/components/common/SearchBox.vue';
import { useWebsiteStore } from '~/units/website/store';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'Theme5BlogsPage',
  components: { SearchBox, NcButton, NotFound, Loader },

  mixins: [BlogsMixin, indexGlobal, ComponentMixin],

  computed: {
    ...mapState(useWebsiteStore, ['theme', 'sidebarComponents']),

    isLatestCarouselEnabled() {
      return this.vars.isLatestCarouselEnabledNumber;
    },

    isLatestCarouselVisible() {
      const route = useRoute();
      return this.isLatestCarouselEnabled && this.blogs.length !== 0 && !route.query.q && !route.query.page;
    },

    isPaginationEnabled() {
      return this.vars.isPaginationEnabledBool;
    },

    isPaginationVisible() {
      return this.isPaginationEnabled && !this.isBlogsLoading;
    },

    variant() {
      return useVariant(this.component);
    },

    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    titleHtml() {
      return this.vars.titleHtml;
    },

    ROUTES() {
      return ROUTES;
    },

    is() {
      return defineAsyncComponent(
        /* TODO: ~ didn't work when I used it. */
        () => import(`../AvailableComponentsForSidebar.vue`),
      );
    },
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchBlogsInPage(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      keyword: '',
      searchBoxPlaceholder: 'Search our articles',
    };
  },

  mounted() {
    const route = useRoute();
    // TODO: Check where we are in blogs list or not
    if (route.query.q) {
      this.keyword = route.query.q as string;
    }
  },

  methods: {
    async onSearched() {
      const blogsStore = useBlogsStore();
      await blogsStore.searchBlogs(this.keyword);
    },
  },

  watch: {
    async $route(a, b) {
      if (a.query.q !== b.query.q) {
        await fetchBlogsManager(this.component, a.query && a.query.q ? a.query.q : null);
      }
    },
  },
});
</script>
