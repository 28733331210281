<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-mx-auto tw-flex tw-w-full tw-max-w-7xl tw-flex-col tw-gap-10">
      <div class="tw-flex tw-flex-col tw-gap-10 md:tw-flex-row">
        <div class="md:tw-w-1/2">
          <h2 class="heading-medium tw-mb-4" :style="`color: var(--c-${vars.titleColor});`">
            {{ vars.titleText }}
          </h2>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-3 md:tw-w-1/2">
          <p
            v-for="(item, index) in descriptions"
            :key="`ourvaluations-description-${index}`"
            class="tw-text-base tw-leading-relaxed"
            :style="{ color: 'var(--c-tertiary)' }"
          >
            {{ item.description }}
          </p>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-2 lg:tw-gap-12">
        <div
          v-for="(valuation, index) in valuations"
          :key="`ourvaluations-valuation-${index}`"
          class="card-box tw-group tw-overflow-hidden"
          :style="`border-radius: var(--rounded);`"
        >
          <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-gap-6">
            <div class="card-photo tw-relative tw-w-full tw-overflow-hidden" :style="`border-radius: var(--rounded);`">
              <LibraryImage
                :src="valuation.photo"
                :h-ratio="1"
                :w-ratio="1.5"
                class="tw-h-auto tw-w-auto tw-object-cover tw-duration-500 group-hover:tw-scale-110"
              />
              <!-- Hover Overlay -->
              <div
                class="tw-absolute tw-inset-0 tw-bg-[var(--c-secondary)] tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-30"
                :style="`border-radius: var(--rounded);`"
              ></div>
            </div>
            <div class="tw-flex tw-flex-grow tw-flex-col tw-gap-6">
              <h4 :style="{ color: 'var(--c-primary)' }">
                {{ valuation.title }}
              </h4>
              <p class="tw-text-base tw-leading-tight">
                {{ valuation.description }}
              </p>
              <div class="tw-mt-auto">
                <nuxt-link
                  :to="valuation.button_link ? valuation.button_link : ''"
                  :external="true"
                  target="_blank"
                  class="n-link tw-flex tw-items-center tw-text-[var(--c-secondary)]"
                >
                  GET THIS VALUATION
                  <svg
                    class="tw-ml-6"
                    width="5"
                    height="8"
                    viewBox="0 0 5 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.59155 4.41479L1.29163 7.71471L0.466797 6.88987L3.3543 4.00237L0.466797 1.11487L1.29163 0.290039L4.59155 3.58996C4.7009 3.69935 4.76234 3.84769 4.76234 4.00237C4.76234 4.15705 4.7009 4.3054 4.59155 4.41479Z"
                      fill="#F46F2C"
                    />
                  </svg>
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'FAQSection',
  mixins: [ComponentMixin],
  computed: {
    valuations() {
      return this.groupedVariables.valuations;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
