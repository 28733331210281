<template>
  <LibraryPopupsVideoPopup
    v-if="vars.isPopupBool && vars.videoUrl"
    :videoUrl="vars.videoUrl"
    v-model:is-visible="isVisible"
    :portrait="vars.isVerticalBool"
  />

  <section
  class="n-section-primary"
  :style="`background-color: var(--c-${vars.backgroundColor})`"
>
  <div
    class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 lg:tw-gap-x-20"
    style="color: var(--c-text-primary)"
  >
    <!-- Medya Alanı (Sol Taraf) -->
    <div
      class="tw-relative lg:tw-col-span-6 lg:tw-row-span-2 tw-self-center tw-object-cover"
      :class="{
        'tw-mr-10': vars.thumbnailCornerText === 'bottom-right' || vars.thumbnailCornerText === 'top-right',
        'tw-ml-10': vars.thumbnailCornerText === 'bottom-left' || vars.thumbnailCornerText === 'top-left',
        'tw-mt-8 lg:tw-mt-0': vars.thumbnailCornerText === 'top-left' || vars.thumbnailCornerText === 'top-right',
        'lg:tw-col-start-1 lg:tw-row-start-1': vars.isReversedBool
      }"
    >
      
      <LibraryPopupsVideoPopup
        v-if="vars.isPopupBool && vars.videoUrl"
        :videoUrl="vars.videoUrl"
        v-model:is-visible="isVisible"
        :portrait="vars.isVerticalBool"
      />

      
      <div
        v-if="vars.photoImage && vars.videoUrl && vars.isPopupBool"
        class="tw-relative tw-w-full tw-h-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
        @click="isVisible = true"
      >
        <NuxtImg
          :src="vars.photoImage"
          alt="Media Image"
          class="tw-w-full tw-h-full tw-object-cover"
        />
        <button
          class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2"
        >
          <svg width="80" height="80" viewBox="0 0 162 162" xmlns="http://www.w3.org/2000/svg">
            <circle cx="81" cy="81" r="81" fill="white" />
            <polygon points="65,45 115,81 65,117" fill="black" />
          </svg>
        </button>
      </div>

     
      <div
        v-else-if="vars.photoImage && !vars.videoUrl"
        class="tw-relative tw-w-full tw-h-full tw-overflow-hidden tw-rounded-[var(--rounded)]"
      >
        <NuxtImg
          :src="vars.photoImage"
          alt="Media Image"
          class="tw-w-full tw-h-full tw-object-cover"
        />
      </div>

      
      <iframe
        v-else-if="!vars.isPopupBool && vars.videoUrl"
        class="tw-w-full tw-h-full tw-object-cover tw-rounded-[var(--rounded)]"
        :src="vars.videoUrl"
        :style="vars.isVerticalBool ? 'aspect-ratio:5/8;' : 'aspect-ratio: 16/9;'"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>

    
    <div class="lg:tw-col-span-6 tw-flex tw-flex-col tw-justify-center tw-gap-8 tw-my-8">
    
      <h2 :style="`color: var(--c-${vars.titleColor});`" class="tw-text-3xl tw-font-bold">
        {{ vars.titleText }}
      </h2>

    
      <p
        v-for="({ description }, index) in descriptions"
        :key="`whybox-description-${index}`"
      >
        {{ description }}
      </p>

      
      <div class="tw-grid tw-gap-x-10 tw-gap-y-2 tw-pt-1 sm:tw-mr-auto sm:tw-grid-cols-2">
        <div class="tw-flex tw-flex-col tw-gap-y-2">
          <div
            v-if="featuresColumns1"
            v-for="(feature, index) in featuresColumns1"
            :key="index"
            class="tw-flex tw-items-center tw-gap-3"
          >
            <svg
              class="tw-shrink-0"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                fill="var(--c-primary)"
              />
            </svg>
            <span>{{ feature.title }}</span>
          </div>

          
          <div class="tw-mt-6 tw-flex tw-flex-wrap tw-justify-start tw-gap-3">
            <nuxt-link
              v-for="(button, index) in buttons"
              :key="`button-${index}`"
              :class="[
                buttons.length >= 2 ? 'tw-w-full md:tw-w-auto' : 'tw-w-auto',
                `n-${button.button_type}`,
                'tw-uppercase',
              ]"
              :to="button.button_link"
            >
              {{ button.button_label }}
              <span class="btn-arrow">
                <svg
                  width="9"
                  height="15"
                  viewBox="0 0 7 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-y-2">
          <div
            v-if="featuresColumns2"
            v-for="(feature, index) in featuresColumns2"
            :key="index"
            class="tw-flex tw-items-center tw-gap-3"
          >
            <svg
              class="tw-shrink-0"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5501 18L3.8501 12.3L5.2751 10.875L9.5501 15.15L18.7251 5.97498L20.1501 7.39998L9.5501 18Z"
                fill="var(--c-primary)"
              />
            </svg>
            <span>{{ feature.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhyBox',

  mixins: [ComponentMixin],

  data() {
    return {
      isVisible: false,
      
    };
  },

  computed: {
    featuresColumns1() {
      return this.groupedVariables.column_1_features;
    },
    featuresColumns2() {
      return this.groupedVariables.column_2_features;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>

<style scoped>
@media (max-width: 1280px) {
  section.n-section-tertiary {
    padding-top: var(--tertiary_section_padding);
    padding-bottom: var(--tertiary_section_padding);
    padding-left: calc((100vw - 100%) / 2) !important;
    padding-right: calc((100vw - 100%) / 2) !important;
  }
}
</style>
