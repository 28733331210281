<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-mb-12">
      <h1 :style="`color: var(--c-${vars.titleColor});`">{{ vars.titleText }}</h1>
    </div>
    <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row lg:tw-justify-center">
      <div
        v-for="(count, index) in counts"
        :key="`countsection-count-${index}`"
        class="tw-flex tw-flex-col tw-items-center tw-justify-start tw-gap-8 tw-break-all tw-py-6 tw-text-center md:tw-py-10 lg:tw-w-full lg:tw-py-20 2xl:tw-py-24"
        ref="countElements"
      >
        <h2 class="odometer tw-whitespace-nowrap" :style="`color: var(--c-${vars.textColor});`"></h2>

        <p class="tw-mx-6 tw-min-h-[48px]" :style="`color: var(--c-${vars.textColor});`">
          {{ count.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'CountSection',
  mixins: [ComponentMixin],

  data() {
    return {
      observers: [] as IntersectionObserver[],
      animatedElements: new Set(),
    };
  },

  computed: {
    counts() {
      return this.groupedVariables.counts;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setupIntersectionObservers();
    });
  },

  beforeUnmount() {
    this.observers.forEach((observer) => observer.disconnect());
  },

  methods: {
    setupIntersectionObservers() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      const elements = this.$refs.countElements;
      if (!elements) return;

      (elements as Element[]).forEach((element, index) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !this.animatedElements.has(index)) {
              this.updateCount(index);
              this.animatedElements.add(index);
              observer.unobserve(entry.target);
            }
          });
        }, options);

        observer.observe(element);
        this.observers.push(observer);
      });
    },

    updateCount(index: number) {
      const count = this.counts[index];
      const element = this.$el.querySelectorAll('.odometer')[index];
      if (element) {
        this.animateValue(element, 0, parseInt(count.title, 10), 2000, count.prefix || '', count.suffix || '');
      }
    },

    animateValue(element: HTMLElement, start: number, end: number, duration: number, prefix = '', suffix = '') {
      let startTime: number | null = null;

      const easeOutQuad = (t: number) => {
        return t * (2 - t);
      };

      const step = (timestamp: number) => {
        if (!startTime) {
          startTime = timestamp;
        }
        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easedProgress = easeOutQuad(progress);

        const currentValue = Math.floor(easedProgress * (end - start) + start).toLocaleString();

        element.innerText = `${prefix}${currentValue}${suffix}`;

        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    },
  },
});
</script>
