<template>
  <section
    v-if="property && version === 'v1'"
    class="section-product-content pb-5"
    style="background-color: var(--white)"
  >
    <div class="page-padding">
      <div class="container-large">
        <div
          class="tw-flex tw-flex-row tw-items-center tw-overflow-x-auto"
          style="
            background-color: rgba(234, 228, 215, 0.25);
            border: solid var(--seashell);
            border-width: 1px 1px 0 1px;
          "
        >
          <h4
            class="heading-small py-3 px-4 n-cursor-pointer"
            :class="activeTab === 1 ? 'active-tab' : ''"
            style="font-size: 20px"
            @click="changeTab(1)"
          >
            Property Info
          </h4>
          <h4
            :class="activeTab === 2 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(2)"
          >
            Map
          </h4>
          <h4
            v-if="property.floor_plans && property.floor_plans.length > 0"
            :class="activeTab === 3 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(3)"
          >
            Floor Plan
          </h4>
          <h4
            v-if="property.epcs && property.epcs.length > 0"
            :class="activeTab === 4 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(4)"
          >
            EPC
          </h4>
          <h4
            v-if="property.extra_medias && property.extra_medias.length > 0"
            :class="activeTab === 5 ? 'active-tab' : ''"
            class="heading-small px-4 py-3 n-cursor-pointer"
            style="font-size: 20px"
            @click="changeTab(5)"
          >
            Media
          </h4>
        </div>
        <div
          v-show="activeTab === 1"
          style="background-color: var(--white); border: solid var(--seashell); border-width: 0 1px 1px 1px"
          class="px-5 py-5"
        >
          <h4 v-if="property.description" class="heading-small mb-4">Description</h4>
          <div v-if="property.description">
            <div v-html="property.description" style="white-space: pre-wrap"></div>
          </div>
          <div class="tw-mt-10">
            <library-properties-property-criteria-list :property="property">
              <template #section-title="{ title }">
                <library-title tag="h3" :text="title"></library-title>
              </template>
              <template #list-item="{ criteria }">
                <library-title
                  tag="h5"
                  :text="criteria.title"
                  custom-style="letter-spacing: 5px; color: var(--brown--secondary); text-transform: uppercase; font-weight: 300 !important"
                ></library-title>
                <library-text class="tw-capitalize" :text="criteria.value"></library-text>
              </template>
            </library-properties-property-criteria-list>
          </div>
        </div>
        <div
          v-show="activeTab === 2"
          style="background-color: var(--white); border: solid var(--seashell); border-width: 0 1px 1px 1px"
          class="px-5 py-5"
        >
          <neuron-google-map
            v-if="mapCenterPosition && mapCenterPosition.lat && mapCenterPosition.lng"
            v-model:map-center-position="mapCenterPosition"
            :component="component"
            :marker-color="markerColor"
          />
        </div>
        <div
          v-if="property.floor_plans && property.floor_plans.length > 0"
          v-show="activeTab === 3"
          style="background-color: var(--white); border: solid var(--seashell); border-width: 0 1px 1px 1px"
          class="px-5 py-5"
        >
          <floor-plan :data="property.floor_plans" :component="component" />
        </div>
        <div
          v-if="property.epcs && property.epcs.length > 0"
          v-show="activeTab === 4"
          style="background-color: var(--white); border: solid var(--seashell); border-width: 0 1px 1px 1px"
          class="px-5 py-5"
        >
          <epc :data="property.epcs" :component="component" />
        </div>
        <div
          v-if="property.extra_medias && property.extra_medias.length > 0"
          v-show="activeTab === 5"
          style="background-color: var(--white); border: solid var(--seashell); border-width: 0 1px 1px 1px"
          class="px-5 py-5"
        >
          <extra-media :data="property.extra_medias" :component="component" />
        </div>
      </div>
    </div>
  </section>
  <div v-if="property && version === 'v2'" class="n-section-primary !tw-pt-0" style="background-color: var(--white)">
    <div
      class="tw-flex tw-w-full tw-flex-row tw-items-center tw-overflow-x-auto"
      style="
        background-color: var(--c-section_primary);
        border: solid var(--c-border);
        border-width: 2px 2px 0 2px;
        border-top-right-radius: var(--rounded);
        border-top-left-radius: var(--rounded);
      "
    >
      <h5
        class="py-3 px-4 n-cursor-pointer"
        :class="activeTab === 1 ? 'active-tab-2' : ''"
        style="font-size: 20px"
        @click="changeTab(1)"
      >
        Property Info
      </h5>
      <h5
        :class="activeTab === 2 ? 'active-tab-2' : ''"
        class="px-4 py-3 n-cursor-pointer"
        style="font-size: 20px"
        @click="changeTab(2)"
      >
        Map
      </h5>
      <h5
        v-if="property.floor_plans && property.floor_plans.length > 0"
        :class="activeTab === 3 ? 'active-tab-2' : ''"
        class="px-4 py-3 n-cursor-pointer"
        style="font-size: 20px"
        @click="changeTab(3)"
      >
        Floor Plan
      </h5>
      <h5
        v-if="property.epcs && property.epcs.length > 0"
        :class="activeTab === 4 ? 'active-tab-2' : ''"
        class="px-4 py-3 n-cursor-pointer"
        style="font-size: 20px"
        @click="changeTab(4)"
      >
        EPC
      </h5>
      <h5
        v-if="property.extra_medias && property.extra_medias.length > 0"
        :class="activeTab === 5 ? 'active-tab-2' : ''"
        class="px-4 py-3 n-cursor-pointer"
        style="font-size: 20px"
        @click="changeTab(5)"
      >
        Media
      </h5>
    </div>
    <div
      v-show="activeTab === 1"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="p-4"
    >
      <h4 v-if="property.description" class="mb-4">Description</h4>
      <div v-if="property.description">
        <div v-html="property.description" style="white-space: pre-wrap"></div>
      </div>
      <div class="tw-mt-10">
        <library-properties-property-criteria-list :property="property">
          <template #section-title="{ title }">
            <library-title tag="h3" :text="title"></library-title>
          </template>
          <template #list-item="{ criteria }">
            <library-title
              tag="h5"
              :text="criteria.title"
              custom-style="letter-spacing: 5px; color: var(--brown--secondary); text-transform: uppercase; font-weight: 300 !important"
            ></library-title>
            <library-text class="tw-capitalize" :text="criteria.value"></library-text>
          </template>
        </library-properties-property-criteria-list>
      </div>
    </div>
    <div
      v-show="activeTab === 2"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="px-5 py-5 tw-w-full"
    >
      <neuron-google-map
        v-if="property.map_position && property.map_position.lat && property.map_position.lng"
        v-model:map-center-position="property.map_position"
        :component="component"
        :marker-color="markerColor"
      />
    </div>
    <div
      v-if="property.floor_plans && property.floor_plans.length > 0"
      v-show="activeTab === 3"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="px-5 py-5"
    >
      <floor-plan :data="property.floor_plans" :component="component" />
    </div>
    <div
      v-if="property.epcs && property.epcs.length > 0"
      v-show="activeTab === 4"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="px-5 py-5"
    >
      <epc :data="property.epcs" :component="component" />
    </div>
    <div
      v-if="property.extra_medias && property.extra_medias.length > 0"
      v-show="activeTab === 5"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="px-5 py-5 tw-w-full"
    >
      <extra-media :data="property.extra_medias" :component="component" />
    </div>
  </div>
  <div v-if="property && version === 'v3'" class="n-section-primary !tw-pt-0" style="background-color: var(--white)">
    <div
      class="tw-flex tw-w-full tw-flex-row tw-items-center tw-overflow-x-auto"
      style="
        background-color: var(--c-section_primary);
        border: solid var(--c-border);
        border-width: 2px 2px 0 2px;
        border-top-right-radius: var(--rounded);
        border-top-left-radius: var(--rounded);
      "
    >
      <h5
        class="py-3 px-4 n-cursor-pointer"
        :class="activeTab === 1 ? 'active-tab-2' : ''"
        style="font-size: 20px"
        @click="changeTab(1)"
      >
        Mortgage Calculator
      </h5>
      <h5
        :class="activeTab === 2 ? 'active-tab-2' : ''"
        class="px-4 py-3 n-cursor-pointer"
        style="font-size: 20px"
        @click="changeTab(2)"
      >
        Stamp Duty Calculator
      </h5>
    </div>
    <div
      v-show="activeTab === 1"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="p-4 tw-w-full"
    >
      <library-tools-mortgage-calculator
        class="accordion-mortgage-calculator"
        :default-price="property.price.numberPrice"
      />
    </div>
    <div
      v-show="activeTab === 2"
      style="
        background-color: var(--white);
        border: solid var(--c-border);
        border-width: 0 2px 2px 2px;
        border-bottom-left-radius: var(--rounded);
        border-bottom-right-radius: var(--rounded);
      "
      class="px-5 py-5 tw-w-full"
    >
      <library-tools-stamp-duty-calculator v-if="!property.is_locked" :default-price="property.price.numberPrice" />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import NeuronGoogleMap from '~/components/common/NeuronGoogleMap.vue';
import FloorPlan from '~/components/common/FloorPlan.vue';
import Epc from '~/components/common/Epc.vue';
import ExtraMedia from '~/components/common/ExtraMedia.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import type { PropertyItemComponent } from '~/units/properties/types';

export default defineNuxtComponent({
  name: 'PropertyDetailTabs',

  mixins: [ComponentMixin],

  components: {
    ExtraMedia,
    FloorPlan,
    NeuronGoogleMap,
    Epc,
  },

  props: {
    property: {
      required: true,
      type: Object as PropType<PropertyItemComponent>,
    },

    markerColor: {
      required: true,
    },

    version: {
      default: 'v1',
    },
  },

  data() {
    return {
      activeTab: 1,
    };
  },

  computed: {
    mapCenterPosition() {
      if (this.property && this.property.map_position) {
        return {
          lat: this.property.map_position.lat,
          lng: this.property.map_position.lng,
        };
      }
      return null;
    },
  },

  methods: {
    changeTab(newActiveTab: number) {
      this.activeTab = newActiveTab;
    },
  },
});
</script>

<style scoped>
.active-tab {
  background-color: var(--seashell);
}
.active-tab-2 {
  background-color: var(--c-primary);
  color: white;
}
</style>
