<template>
<section
  class="n-section-primary lg:tw-pb-12"
  :style="{ 'background-color': `var(--${vars.backgroundColor})` }"
>
  <div
    class="
      tw-flex 
      tw-flex-col 
      tw-items-start
      tw-gap-6
      lg:tw-flex-row
      lg:tw-items-start
      lg:tw-gap-16
    "
  >
    
    <div class="tw-w-full lg:tw-w-5/12">
      <h2 :style="{ color: `var(--${vars.titleColor})` }">
        {{ vars.titleText }}
      </h2>
    </div>

    
    <div class="tw-w-full lg:tw-w-7/12">
    
      <div 
        class="
          tw-flex 
          tw-flex-col 
          tw-gap-2 
          tw-overflow-auto
        "
      >
        <p
          v-for="({ description }, index) in descriptions"
          :key="`contentbox-description-${index}`"
          :style="{ color: `var(--${vars.textColor})` }"
        >
          {{ description }}
        </p>
      </div>

      <div class="tw-mt-6 tw-flex tw-flex-col tw-gap-3 lg:tw-flex-row lg:tw-flex-wrap lg:tw-justify-start">
  <nuxt-link
    v-for="(button, index) in buttons"
    :key="`button-${index}`"
    :class="[ 
      'tw-w-1/3 lg:tw-w-auto', 
      `n-${button.button_type}`,
      'tw-uppercase tw-text-center'
    ]"
    :to="button.button_link"
  >
    {{ button.button_label }}
    <span class="btn-arrow">
      <svg
        width="9"
        height="15"
        viewBox="0 0 7 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
          fill="white"
        />
      </svg>
    </span>
  </nuxt-link>
</div>
    </div>
  </div>
</section>




</template>

<script lang="ts">
import VideoPopup from './VideoPopup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBoxTheme2',

  mixins: [ComponentMixin],
  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  components: { VideoPopup },

  data() {
    return {
      
    };
  },
});
</script>

