<template>
  <div v-if="variants.v1 === variant">
    <section
      class="section-product-content"
      style="background-color: var(--tertiary_color); z-index: 50"
      :style="!isDefaultTopMarginDisabled ? 'margin-top: 110px;' : ''"
    >
      <div class="page-padding">
        <div class="container-large">
          <div class="row py-5 gx-2 d-flex flex-row align-items-center tw-gap-y-4">
            <div class="col-lg-4 col-md-6 col-12">
              <div
                class="form-field-wrapper d-flex flex-row justify-content-between align-items-center properties-filter-item"
              >
                <locations v-model="searchInput" v-model:locations="locations" />
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-12 h-100">
              <div class="w-100 form-field-wrapper d-flex flex-row align-items-center">
                <multiple-input-group popup-width="auto">
                  <template #front-item>
                    <div
                      class="w-100 text-uppercase d-flex flex-row align-items-center justify-content-center properties-filter-item"
                    >
                      {{ minPriceLabel }}<span class="px-2">-</span>{{ maxPriceLabel }}
                      {{ !filters.min_price && !filters.max_price ? 'PRICE' : '' }}
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                      </svg>
                    </div>
                  </template>
                  <template #group>
                    <div class="d-flex flex-row">
                      <select v-model="filters.min_price" style="border: unset !important">
                        <option
                          v-for="(minPriceOption, index) in minPrices"
                          :key="`minPriceOption-${index}`"
                          :value="minPriceOption.value"
                        >
                          {{ minPriceOption.display }}
                        </option>
                      </select>
                      <div class="px-2">-</div>
                      <select v-model="filters.max_price" style="border: unset !important">
                        <option
                          v-for="(maxPriceOption, index) in maxPrices"
                          :key="`maxPriceOption-${index}`"
                          :value="maxPriceOption.value"
                        >
                          {{ maxPriceOption.display }}
                        </option>
                      </select>
                    </div>
                  </template>
                </multiple-input-group>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-12">
              <div class="w-100 h-100 form-field-wrapper d-flex flex-row justify-content-center align-items-center">
                <multiple-input-group popup-width="300px">
                  <template #front-item>
                    <div
                      class="w-100 text-uppercase d-flex flex-row align-items-center justify-content-center properties-filter-item"
                    >
                      {{ minBedroomLabel }}<span class="px-2">-</span>{{ maxBedroomLabel }}
                      BEDS
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                      </svg>
                    </div>
                  </template>
                  <template #group>
                    <div class="d-flex flex-row row g-0">
                      <select v-model="filters.min_bedroom" class="col-5" style="border: unset !important">
                        <option
                          v-for="(bedroomCount, index) in minBedroomCounts"
                          :key="`bedroomCountForMin-${index}`"
                          :value="bedroomCount.value"
                        >
                          {{ bedroomCount.name }}
                        </option>
                      </select>
                      <div class="col-2 text-center">-</div>
                      <select v-model="filters.max_bedroom" class="col-5" style="border: unset !important">
                        <option
                          v-for="(bedroomCount, index) in maxBedroomCounts"
                          :key="`bedroomCountForMax-${index}`"
                          :value="bedroomCount.value"
                        >
                          {{ bedroomCount.name }}
                        </option>
                      </select>
                    </div>
                  </template>
                </multiple-input-group>
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-12" @click="turnOnAdvancedPopup()">
              <div
                class="d-flex flex-row px-4 justify-content-center justify-content-lg-around align-items-center n-cursor-pointer properties-filter-item"
              >
                <div>
                  FILTERS
                  <span v-if="advancedFilterSelectionCount > 0">({{ advancedFilterSelectionCount }})</span>
                </div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7 10L12 15L17 10H7Z" fill="#AF624E" />
                </svg>
              </div>
            </div>
            <div class="col-lg-2 col-md-12 col-12">
              <nc-button label="SEARCH" :height="55" @on-click="onSearch()"> </nc-button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="filterSummary"
      class="section-product-content py-4"
      style="background-color: var(--white); border-bottom: 1px solid black; z-index: 40"
    >
      <div class="page-padding">
        <div class="container-large">
          <div class="row g-0 d-flex justify-content-between align-items-center">
            <div class="col-12 col-lg-6">
              {{ filterSummary }}
            </div>
            <div
              class="col-12 col-lg-6 mt-4 mt-lg-0 d-flex flex-row justify-content-lg-end align-items-center"
              :class="isApplicantExist ? 'col-xl-4' : 'col-xl-3'"
            >
              <div
                v-if="isApplicantExist"
                class="d-flex flex-row align-items-center n-cursor-pointer me-3"
                @click="onClickedProfile()"
              >
                <span class="nc-icon-profile-user-1 me-2" style="font-size: 24px"> </span>
                <div class="text-capitalize text-truncate">
                  {{ contactSession.name }}
                </div>
              </div>

              <nc-button v-if="!isApplicantExist" label="Setup Heads Up Alert" @on-click="setupHeadsUpAlerts()" />
              <nc-button v-if="isApplicantExist" label="Update Heads Up Alert" @on-click="updateHeadsUpAlerts()" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="isLoading" class="section-product-content py-5" style="background-color: var(--white)">
      <div class="page-padding">
        <div class="container-large">
          <loader />
        </div>
      </div>
    </section>
    <section
      v-else-if="!isLoading && (highlightedProperties.length || properties.length)"
      class="section-product-content pt-3 pb-5"
      style="background-color: var(--white)"
    >
      <div class="page-padding">
        <div class="container-large">
          <div v-if="locations.length > 0" class="row g-0 mb-3">
            <div class="col-12">
              <location-boxes v-model:locations="locations" :is-first-location-excluded="true" />
            </div>
          </div>
          <template v-if="highlightedProperties.length">
            <div v-if="highlightedProperties.length > 0" class="row g-0 mb-5 d-flex flex-row justify-content-between">
              <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
                <h2 class="heading-medium">
                  New matches:
                  {{ newMatchesPropertyCount }} properties
                </h2>
              </div>
              <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
                <div class="n-select n-sort-select w-100">
                  <select v-model="filterCriteria.sort" class="p-3 w-100 n-select-box" @change="sort()">
                    <option
                      v-for="(option, index) in sortOptions"
                      :key="`sortOption-${index}`"
                      :value="option.value"
                      :class="filterCriteria.sort === option.value ? 'n-select-selected-option' : ''"
                    >
                      {{ option.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <properties-list-box
              v-if="highlightedProperties.length > 0"
              :component="component"
              :properties="highlightedProperties"
            />
          </template>

          <div class="row g-0 ps-md-2 my-5 d-flex flex-row justify-content-between align-items-center">
            <div class="col-md-6 col-lg-6 col-xl-8 col-12">
              <h2 v-if="highlightedProperties.length === 0" class="heading-medium">{{ count }} properties</h2>
              <h2 v-else-if="highlightedProperties.length > 0 && properties.length" class="heading-medium">
                Existing matches:
                {{ count - highlightedProperties.length }} properties
              </h2>
            </div>
            <div class="col-md-4 col-lg-3 col-xl-2 col-12 mt-4 mt-md-0 d-flex justify-content-end">
              <div v-if="highlightedProperties.length === 0" class="n-select n-sort-select w-100">
                <select v-model="filterCriteria.sort" class="p-3 w-100 n-select-box" @change="sort()">
                  <option
                    v-for="(option, index) in sortOptions"
                    :key="`sortOption-${index}`"
                    :value="option.value"
                    :class="filterCriteria.sort === option.value ? 'n-select-selected-option' : ''"
                  >
                    {{ option.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <properties-list-box :component="component" :properties="properties" />
          <div v-if="isPropertiesGridLoadMoreActive" class="row g-0 mt-5">
            <div class="col-12 d-flex flex-row justify-content-center">
              <div
                class="p-4 n-cursor-pointer"
                style="
                  border: 1px solid #aca39a;
                  color: var(--tertiary_color);
                  letter-spacing: 3px;
                  font-size: 14px;
                  font-weight: lighter;
                "
                @click="loadMore()"
              >
                LOAD MORE
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      v-else-if="properties && properties.length === 0"
      class="section-product-content py-5"
      style="background-color: var(--white)"
    >
      <div class="page-padding">
        <div class="container-large">
          <lazy-theme2-base-not-found title="Unfortunately, there are no properties matching your search criteria.">
            <template #description>
              Please
              <nuxt-link :to="routes.REGISTER" :external="true"
                ><u class="unknown-user-text text">setup a Heads Up property alert</u></nuxt-link
              >
              to be kept up to date in the future with new suggested properties that are based around your specific
              criteria.
            </template>
          </lazy-theme2-base-not-found>
        </div>
      </div>
    </section>
    <advanced-search
      v-if="!isMobile && isExtendedFilterPopupVisible"
      v-model:filters="filters"
      v-model:is-available="searchInput.is_available"
      :search-type="searchType"
      @on-open-all-styles-popup="allStylesConfig.isActive = true"
      theme="theme2"
      @open-extended-popup="turnOffAdvancedPopup()"
      @close-advanced-filter="turnOffAdvancedPopup()"
    />

    <popup v-if="isMobile" :config="advancedSearchConfig" custom-width="auto">
      <template #content>
        <advanced-search
          v-if="isExtendedFilterPopupVisible"
          v-model:filters="filters"
          v-model:is-available="searchInput.is_available"
          :search-type="searchType"
          @on-open-all-styles-popup="allStylesConfig.isActive = true"
          @on-searched="onSearch()"
        />
      </template>
    </popup>

    <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
      <template #content>
        <all-property-types-styles
          v-model:selected-type-styles="filters.property_types"
          @close="allStylesConfig.isActive = false"
        />
      </template>
    </popup>

    <popup
      v-if="applicantProfilesPopupConfig.isActive"
      v-model:config="applicantProfilesPopupConfig"
      custom-width="500px"
    >
      <template #content>
        <applicant-profiles :search-type="searchType" @on-selected-new-applicant="onSelectedNewApplicant()" />
      </template>
    </popup>
  </div>
  <section
    v-else-if="variants.v2 === variant"
    class="n-section-primary !tw-pt-16"
    style="background-color: var(--c-section_tertiary)"
  >
    <div class="tw-w-full" style="z-index: 50" :style="!isDefaultTopMarginDisabled ? 'margin-top: 110px;' : ''">
      <div class="row py-5 gx-2 d-flex flex-row align-items-center tw-gap-y-6">
        <div class="col-lg-4 col-md-6 col-12">
          <div class="form-field-wrapper d-flex flex-row justify-content-between align-items-center">
            <locations v-model="searchInput" v-model:locations="locations" />
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-12 h-100">
          <div class="w-100 form-field-wrapper d-flex flex-row align-items-center">
            <multiple-input-group popup-width="auto">
              <template #front-item>
                <div
                  class="w-100 properties-filter-item d-flex flex-row align-items-center justify-content-center tw-px-4"
                >
                  <div v-if="!filters.min_price && !filters.max_price">Price Interval</div>
                  <div v-else>{{ minPriceLabel }}<span class="px-2">-</span>{{ maxPriceLabel }}</div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="#545454" />
                  </svg>
                </div>
              </template>
              <template #group>
                <div class="d-flex flex-row">
                  <select v-model="filters.min_price" style="border: unset !important">
                    <option
                      v-for="(minPriceOption, index) in minPrices"
                      :key="`minPriceOption-${index}`"
                      :value="minPriceOption.value"
                    >
                      {{ minPriceOption.display }}
                    </option>
                  </select>
                  <div class="px-2">-</div>
                  <select v-model="filters.max_price" style="border: unset !important">
                    <option
                      v-for="(maxPriceOption, index) in maxPrices"
                      :key="`maxPriceOption-${index}`"
                      :value="maxPriceOption.value"
                    >
                      {{ maxPriceOption.display }}
                    </option>
                  </select>
                </div>
              </template>
            </multiple-input-group>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-6">
          <div class="w-100 h-100 form-field-wrapper d-flex flex-row justify-content-center align-items-center">
            <multiple-input-group popup-width="300px">
              <template #front-item>
                <div
                  class="w-100 d-flex flex-row align-items-center justify-content-center properties-filter-item tw-px-4"
                >
                  <div>
                    {{ minBedroomLabel }}<span class="px-1">-</span>{{ maxBedroomLabel }}
                    Beds
                  </div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10L12 15L17 10H7Z" fill="#545454" />
                  </svg>
                </div>
              </template>
              <template #group>
                <div class="d-flex flex-row row g-0 tw-w-full">
                  <select v-model="filters.min_bedroom" class="col-5" style="border: unset !important">
                    <option
                      v-for="(bedroomCount, index) in minBedroomCounts"
                      :key="`bedroomCountForMin-${index}`"
                      :value="bedroomCount.value"
                    >
                      {{ bedroomCount.name }}
                    </option>
                  </select>
                  <div class="col-2 text-center">-</div>
                  <select v-model="filters.max_bedroom" class="col-5" style="border: unset !important">
                    <option
                      v-for="(bedroomCount, index) in maxBedroomCounts"
                      :key="`bedroomCountForMax-${index}`"
                      :value="bedroomCount.value"
                    >
                      {{ bedroomCount.name }}
                    </option>
                  </select>
                </div>
              </template>
            </multiple-input-group>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-6" @click="turnOnAdvancedPopup()">
          <div
            class="d-flex flex-row px-4 justify-content-center justify-content-lg-around align-items-center n-cursor-pointer properties-filter-item"
          >
            <div>
              Filter
              <span v-if="advancedFilterSelectionCount > 0">({{ advancedFilterSelectionCount }})</span>
            </div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12 7.5H7C6.60218 7.5 6.22064 7.65804 5.93934 7.93934C5.65804 8.22064 5.5 8.60218 5.5 9V17C5.5 17.3978 5.65804 17.7794 5.93934 18.0607C6.22064 18.342 6.60218 18.5 7 18.5H15C15.3978 18.5 15.7794 18.342 16.0607 18.0607C16.342 17.7794 16.5 17.3978 16.5 17V12"
                stroke="#545454"
                stroke-width="1.2"
                stroke-linecap="round"
              />
              <path
                d="M12.5 11.5L18.864 5.136M14.5 4.5H19.5V9.5"
                stroke="#545454"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 col-12 nc-search-btn">
          <nc-button
            @on-click="onSearch()"
            class="n-link tw-w-full !tw-bg-[var(--c-secondary)] !tw-text-white hover:tw-bg-[var(--c-primary)]"
            label="SEARCH"
            style="padding: var(--button_padding-secondary) !important"
          >
            SEARCH
          </nc-button>
        </div>
      </div>
    </div>

    <div v-if="filterSummary" class="tw-w-full">
      <div>
        <div>
          <div class="row g-0 d-flex justify-content-between align-items-center">
            <p class="col-12 col-lg-6">
              {{ filterSummary }}
            </p>
            <div
              class="col-12 col-lg-6 mt-4 mt-lg-0 d-flex flex-row justify-content-lg-end align-items-center"
              :class="isApplicantExist ? 'col-xl-4' : 'col-xl-3'"
            >
              <div
                v-if="isApplicantExist"
                class="d-flex flex-row align-items-center n-cursor-pointer me-3"
                @click="onClickedProfile()"
              >
                <span class="nc-icon-profile-user-1 me-2" style="font-size: 24px"> </span>
                <div class="text-capitalize text-truncate">
                  {{ contactSession.name }}
                </div>
              </div>
              <div class="nc-search-btn">
                <nc-button v-if="!isApplicantExist" label="HEADS UP PROPERTY ALERTS" @on-click="setupHeadsUpAlerts()" />
                <nc-button v-if="isApplicantExist" label="HEADS UP PROPERTY ALERTS" @on-click="updateHeadsUpAlerts()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="tw-w-full">
      <div>
        <div>
          <loader />
        </div>
      </div>
    </div>
    <div class="tw-w-full" v-else-if="!isLoading && (highlightedProperties.length || properties.length)">
      <div>
        <div class="tw-w-full">
          <div v-if="locations.length > 0" class="row g-0 mb-3">
            <div class="col-12">
              <location-boxes v-model:locations="locations" :is-first-location-excluded="true" />
            </div>
          </div>
          <template v-if="highlightedProperties.length">
            <div v-if="highlightedProperties.length > 0" class="row g-0 mb-5 d-flex flex-row justify-content-between">
              <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
                <h2 class="heading-medium">
                  New matches:
                  {{ newMatchesPropertyCount }} properties
                </h2>
              </div>
              <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
                <div class="n-select n-sort-select w-100">
                  <select v-model="filterCriteria.sort" class="p-3 w-100 n-select-box" @change="sort()">
                    <option
                      v-for="(option, index) in sortOptions"
                      :key="`sortOption-${index}`"
                      :value="option.value"
                      :class="filterCriteria.sort === option.value ? 'n-select-selected-option' : ''"
                    >
                      {{ option.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <properties-list-box
              v-if="highlightedProperties.length > 0"
              :component="component"
              :properties="highlightedProperties"
            />
          </template>

          <div class="row g-0 ps-md-2 my-5 d-flex flex-row justify-content-between align-items-center">
            <div class="col-md-6 col-lg-6 col-xl-8 col-12">
              <h3 style="color: var(--c-primary)" v-if="highlightedProperties.length === 0">{{ count }} Properties</h3>
              <h3 style="color: var(--c-primary)" v-else-if="highlightedProperties.length > 0 && properties.length">
                Existing matches:
                {{ count - highlightedProperties.length }} Properties
              </h3>
            </div>
            <div class="col-md-4 col-lg-3 col-xl-2 col-12 mt-4 mt-md-0 d-flex justify-content-end">
              <div v-if="highlightedProperties.length === 0" class="n-select n-sort-select w-100">
                <select v-model="filterCriteria.sort" class="p-3 w-100 n-select-box" @change="sort()">
                  <option
                    v-for="(option, index) in sortOptions"
                    :key="`sortOption-${index}`"
                    :value="option.value"
                    :class="filterCriteria.sort === option.value ? 'n-select-selected-option' : ''"
                  >
                    {{ option.title }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <properties-list-box
            :component="component"
            :properties="properties"
            version="v2"
            :custom-card-index="vars.customCardNumber"
            :variables="customCard"
          />
          <div v-if="isPropertiesGridLoadMoreActive" class="row g-0 mt-5">
            <div class="col-12 d-flex flex-row justify-content-center">
              <div
                class="p-4 n-cursor-pointer"
                style="
                  border: 2px solid var(--c-border);
                  border-radius: var(--rounded);
                  color: black;
                  letter-spacing: 3px;
                  font-size: 14px;
                "
                @click="loadMore()"
              >
                LOAD MORE
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="properties && properties.length === 0" class="tw-w-full">
      <div>
        <div>
          <lazy-theme2-base-not-found title="Unfortunately, there are no properties matching your search criteria.">
            <template #description>
              Please
              <nuxt-link :to="routes.REGISTER" :external="true"
                ><u class="unknown-user-text text">setup a Heads Up property alert</u></nuxt-link
              >
              to be kept up to date in the future with new suggested properties that are based around your specific
              criteria.
            </template>
          </lazy-theme2-base-not-found>
        </div>
      </div>
    </div>
    <advanced-search
      v-if="!isMobile && isExtendedFilterPopupVisible"
      v-model:filters="filters"
      v-model:is-available="searchInput.is_available"
      :search-type="searchType"
      @on-open-all-styles-popup="allStylesConfig.isActive = true"
      theme="theme2"
      @open-extended-popup="turnOffAdvancedPopup()"
      @close-advanced-filter="turnOffAdvancedPopup()"
    />

    <popup v-else-if="isMobile" :config="advancedSearchConfig" custom-width="auto">
      <template #content>
        <advanced-search
          v-if="isExtendedFilterPopupVisible"
          v-model:filters="filters"
          v-model:is-available="searchInput.is_available"
          :search-type="searchType"
          @on-open-all-styles-popup="allStylesConfig.isActive = true"
          @on-searched="onSearch()"
        />
      </template>
    </popup>

    <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
      <template #content>
        <all-property-types-styles
          v-model:selected-type-styles="filters.property_types"
          @close="allStylesConfig.isActive = false"
        />
      </template>
    </popup>

    <popup
      v-if="applicantProfilesPopupConfig.isActive"
      v-model:config="applicantProfilesPopupConfig"
      custom-width="500px"
    >
      <template #content>
        <applicant-profiles :search-type="searchType" @on-selected-new-applicant="onSelectedNewApplicant()" />
      </template>
    </popup>
  </section>
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia';
import { ComponentMixin } from '~/mixins/component.mixin';
import CriteriaPreferencesConstants from '~/units/properties/constants/criteriaPreferences.constants';
import Locations from '~/components/common/Locations.vue';
import MultipleInputGroup from '~/components/common/MultipleInputGroup.vue';
import type {
  Location,
  LocationComponent,
  PropertiesFilter,
  PropertiesFilterRouteQueryKeys,
  PropertiesQuery,
  PropertyItemComponent,
} from '~/units/properties/types';
import {
  PFilterBillsIncludes,
  PFilterFurnishing,
  PFilterHouseFlatShare,
  PFilterNewHomes,
  PFilterPetsAllowed,
  PFilterRetirementHomes,
  PFilterSearchType,
  PFilterSharedOwnership,
  PFilterShortLet,
  PFilterSortOptions,
} from '~/units/properties/types';
import PropertiesListBox from '~/components/theme2/components/PropertiesListBox.vue';

import { usePropertiesStore } from '~/units/properties/stores';
import { FetchModes } from '~/units/core/types';
import AdvancedSearch from '~/components/common/properties/AdvancedSearch.vue';
import Popup from '~/components/common/popup/Popup.vue';
import AllPropertyTypesStyles from '~/components/common/properties/AllPropertyTypeStyle.vue';
import Loader from '~/components/common/Loader.vue';
import { useCoreStore } from '~/units/core/store';
import { useApplicantsStore } from '~/units/applicants/store';
import LocationBoxes from '~/components/common/LocationBoxes.vue';
import ApplicantProfiles from '~/components/common/properties/ApplicantProfiles.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { ROUTES } from '~/constants/ui.constants';

export default defineNuxtComponent({
  name: 'PropertiesTheme2',
  components: {
    NcButton,
    ApplicantProfiles,
    LocationBoxes,
    Loader,
    AllPropertyTypesStyles,
    Popup,
    AdvancedSearch,
    PropertiesListBox,
    MultipleInputGroup,
    Locations,
  },

  mixins: [ComponentMixin],

  data(): {
    [key: string]: any;

    filterCriteria: { sort: PFilterSortOptions | null };
  } {
    return {
      allStylesConfig: { isActive: false },

      variants: {
        v1: 'v1',
        v2: 'v2',
      },

      applicantProfilesPopupConfig: { isActive: false },

      searchInput: {
        keyword: '',
        mile: 0.5,

        is_available: false,
      },

      filterSummary: '',

      filterCriteria: { sort: null },

      advancedSearchConfig: { isActive: false },

      filters: {
        min_bedroom: null,
        max_bedroom: null,
        min_bathroom: null,
        max_bathroom: null,
        min_reception: null,
        max_reception: null,
        min_price: null,
        max_price: null,

        new_homes: PFilterNewHomes.INCLUDE,
        shared_ownership: PFilterSharedOwnership.INCLUDE,

        must_haves: [],
        retirement_homes: PFilterRetirementHomes.INCLUDE,
        property_types: [],

        // lettings
        short_let: PFilterShortLet.INCLUDE,
        house_flat_share: PFilterHouseFlatShare.INCLUDE,
        pets_allowed: PFilterPetsAllowed.INCLUDE,
        bills_included: PFilterBillsIncludes.INCLUDE,
        furnishing: PFilterFurnishing.ANY,
      },
      locations: [],

      isLoaded: false,

      isExtendedFilterPopupVisible: false,

      searchTypes: PFilterSearchType,

      isLoading: false,
    };
  },

  computed: {
    routes() {
      return ROUTES;
    },

    customCard() {
      return {
        titleText: this.vars.cardTitleText,
        titleColor: this.vars.cardTitleColor,
        textColor: this.vars.cardTextColor,
        descriptionText: this.vars.cardDescriptionText,
        backgroundColor: this.vars.cardBackgroundColor,
        buttonLabelText: this.vars.cardButtonLabelText,
        buttonTypeText: this.vars.cardButtonTypeText,
        buttonLinkUrl: this.vars.cardButtonLinkUrl,
      };
    },

    ...mapState(usePropertiesStore, [
      'propertiesGridList',
      'propertiesGridCount',
      'isPropertiesGridLoadMoreActive',
      'propertiesGridInitialRouteQuery',
      'propertiesGridInitialLoadConfig',
      'propertiesGridAwaitingRouteQuery',
      'highlightedPropertiesGridList',
    ]),

    ...mapState(useCoreStore, ['isMobile']),

    ...mapState(useApplicantsStore, ['loggedContact', 'applicant', 'getApplicantByType']),

    isApplicantExist() {
      return this.getApplicantByType(this.searchType);
    },

    isDefaultTopMarginDisabled() {
      return this.vars.disableDefaultTopMarginBool;
    },

    newMatchesPropertyCount() {
      if (this.highlightedPropertiesGridList.length < 9) {
        return this.highlightedPropertiesGridList.length;
      }
      return this.propertiesGridCount - this.properties.length;
    },

    sortOptions() {
      return [
        { title: 'Suggested', value: PFilterSortOptions.SUGGESTED },
        { title: 'Highest Price', value: PFilterSortOptions.HIGHEST_PRICE },
        { title: 'Lowest Price', value: PFilterSortOptions.LOWEST_PRICE },
        { title: 'Newest Listed', value: PFilterSortOptions.NEWEST_LISTED },
        { title: 'Oldest Listed', value: PFilterSortOptions.OLDEST_LISTED },
      ];
    },

    advancedFilterSelectionCount() {
      let count = 0;

      if (this.filters.min_bathroom || this.filters.max_bathroom) {
        count += 1;
      }

      if (this.filters.min_reception || this.filters.max_reception) {
        count += 1;
      }

      if (this.filters.property_types.length > 0) {
        count += 1;
      }

      return count;
    },

    minPriceLabel() {
      if (this.filters.min_price) {
        const selectedPrice = this.minPrices.find((price) => price.value === this.filters.min_price);
        if (selectedPrice) {
          return selectedPrice.display;
        }
      }
      return 'MIN';
    },

    maxPriceLabel() {
      if (this.filters.max_price) {
        const selectedPrice = this.maxPrices.find((price) => price.value === this.filters.max_price);
        if (selectedPrice) {
          return selectedPrice.display;
        }
      }
      return 'MAX';
    },

    minBedroomLabel() {
      if (this.filters.min_bedroom) {
        const minBedroom = +this.filters.min_bedroom;

        const selected = this.minBedroomCounts.find((item) => item && item.value && +item.value === minBedroom);
        if (selected) {
          return `MIN ${selected.name}`;
        }
      }
      return 'MIN';
    },

    maxBedroomLabel() {
      if (this.filters.max_bedroom) {
        const maxBedroom = +this.filters.max_bedroom;

        const selected = this.maxBedroomCounts.find((item) => item && item.value && +item.value === maxBedroom);
        if (selected) {
          return `MAX ${selected.name}`;
        }
      }
      return 'MAX';
    },

    minPrices() {
      return CriteriaPreferencesConstants.getPrices(this.searchType, 'min', true);
    },

    maxPrices() {
      return CriteriaPreferencesConstants.getPrices(this.searchType, 'max', true);
    },

    minBedroomCounts() {
      return [{ value: null, name: 'No Min' }, ...CriteriaPreferencesConstants.bedroom];
    },

    maxBedroomCounts() {
      return [{ value: null, name: 'No Max' }, ...CriteriaPreferencesConstants.bedroom];
    },

    properties(): PropertyItemComponent[] {
      return this.propertiesGridList;
    },

    highlightedProperties(): PropertyItemComponent[] {
      return this.highlightedPropertiesGridList;
    },

    count() {
      return this.propertiesGridCount;
    },

    searchType(): PFilterSearchType {
      return getVariable(this.component, 'search_type-text') as PFilterSearchType;
    },
  },

  async setup({ component }) {
    const route = useRoute();
    const store = usePropertiesStore();

    const searchType = getVariable(component, 'search_type-text') as PFilterSearchType;
    let defaultSort = store.prepareDefaultSort(component);

    const applicantsStore = useApplicantsStore();

    await applicantsStore.fetchApplicantBySessionAndType(searchType);

    const applicant = applicantsStore.getApplicantByType(searchType);

    const plainQuery = { ...route.query };
    Object.keys(plainQuery).forEach((key) => {
      if (key.includes('[]') || !key || key.includes('utm')) {
        delete plainQuery[key];
      }
    });

    if (Object.keys(plainQuery).length > 0 || applicant) {
      defaultSort = PFilterSortOptions.SUGGESTED;
    }

    const isAvailableDefaultValue =
      (getVariable(component, 'is_available_filter_enabled_default-number') as number) === 1;

    const fetchByQuery = async () => {
      store.setInitialLoadConfig({ is_available: isAvailableDefaultValue });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = { is_available: isAvailableDefaultValue };
      let order: PFilterSortOptions = defaultSort;
      try {
        Object.keys(route.query).forEach((item) => {
          const key = item as PropertiesFilterRouteQueryKeys;
          const value = route.query[key] as string;

          if (value) {
            if (key === 'is_available') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              query[key] = value;
            } else if (key === 'sort') {
              order = value as PFilterSortOptions;
            } else {
              filter[key] = JSON.parse(value);
            }
          }
        });
      } catch (e) {
        // just to escape errors!
      }

      await store.fetch(FetchModes.INIT, 9, order, searchType, query, filter);
    };

    let isAlreadyRedirected;

    if (Object.keys(plainQuery).length > 0) {
      await fetchByQuery();
      isAlreadyRedirected = true;
    } else if (applicant) {
      const store = usePropertiesStore();

      store.setInitialLoadConfig({ is_available: isAvailableDefaultValue });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = { is_available: isAvailableDefaultValue };

      const order: PFilterSortOptions = defaultSort;

      const criteria = applicant.criteria;

      const criteriaKeys = [
        'min_bedroom',
        'max_bedroom',
        'min_bathroom',
        'max_bathroom',
        'min_reception',
        'max_reception',
        'min_price',
        'max_price',
        'locations',
        'property_types',
        'new_homes',
        'retirement_homes',
        'shared_ownership',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'must_haves',
        'furnishing',
      ];

      criteriaKeys.forEach((criteriaKey: string) => {
        if (criteria[criteriaKey]) {
          filter[criteriaKey] = criteria[criteriaKey];
        }
      });

      await store.fetch(FetchModes.LOAD_BY_DATA, 9, order, searchType, query, filter);
    } else if (!applicant) {
      await fetchByQuery();
    }

    return { isAlreadyRedirected };
  },

  async created() {
    if (process.client) {
      await this.handleAwaitingRouteQuery(this.propertiesGridAwaitingRouteQuery);
    }
    this.onAfterSearch();
  },

  methods: {
    ...mapActions(usePropertiesStore, ['fetch', 'handleAwaitingRouteQuery']),

    onAfterSearch() {
      this.parseInitialRouteQuery();
      this.computeFilterSummary();
      this.loadInitialConfig();

      const coreStore = useCoreStore();
      coreStore.updateMetaTagForce('title', this.filterSummary);
    },

    async onSelectedNewApplicant() {
      const isAvailableDefaultValue =
        (getVariable(this.component, 'is_available_filter_enabled_default-number') as number) === 1;

      const store = usePropertiesStore();
      const applicantsStore = useApplicantsStore();
      const applicant = applicantsStore.applicant;

      store.setInitialLoadConfig({ is_available: isAvailableDefaultValue });

      const filter: PropertiesFilter = {};
      const query: PropertiesQuery = { is_available: isAvailableDefaultValue };

      const order: PFilterSortOptions = store.prepareDefaultSort(this.component);

      const criteria = applicant.criteria;

      const criteriaKeys = [
        'min_bedroom',
        'max_bedroom',
        'min_bathroom',
        'max_bathroom',
        'min_reception',
        'max_reception',
        'min_price',
        'max_price',
        'locations',
        'property_types',
        'new_homes',
        'retirement_homes',
        'shared_ownership',
        'short_let',
        'house_flat_share',
        'pets_allowed',
        'bills_included',
        'must_haves',
        'furnishing',
      ];

      criteriaKeys.forEach((criteriaKey: string) => {
        if (criteria[criteriaKey]) {
          filter[criteriaKey] = criteria[criteriaKey];
        }
      });

      await store.fetch(FetchModes.LOAD_BY_DATA, 9, order, this.searchType, query, filter);
      this.applicantProfilesPopupConfig.isActive = false;
      this.onAfterSearch();
    },

    onClickedProfile() {
      this.applicantProfilesPopupConfig.isActive = true;
    },

    turnOffAdvancedPopup() {
      this.isExtendedFilterPopupVisible = false;
      this.advancedSearchConfig.isActive = false;
      this.onSearch();
    },

    turnOnAdvancedPopup() {
      this.isExtendedFilterPopupVisible = !this.isExtendedFilterPopupVisible;
      this.advancedSearchConfig.isActive = !this.advancedSearchConfig.isActive;
    },

    parseInitialRouteQuery() {
      Object.keys(this.propertiesGridInitialRouteQuery).forEach((filterKey: string) => {
        const key = filterKey as PropertiesFilterRouteQueryKeys;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const value = this.propertiesGridInitialRouteQuery[key];

        if (key === 'locations') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          this.locations = value as Location[];
          if (this.locations.length) {
            const firstLocation: Location = this.locations[0];
            this.searchInput.mile = firstLocation.distance;
            this.searchInput.keyword = firstLocation.location;
          }
        } else if (key === 'sort') {
          this.filterCriteria.sort = value;
        } else if (value && key in this.filters) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          this.filters[key] = value;
        } else if (value && key === 'is_available') {
          if (key === 'is_available') {
            this.searchInput.is_available = value === 'true' || value;
          }
        }
      });
    },

    loadInitialConfig() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!('is_available' in this.propertiesGridInitialRouteQuery)) {
        this.searchInput.is_available = this.propertiesGridInitialLoadConfig.is_available;
      }
    },

    mapLocationsForSearch() {
      return this.locations.map((location: LocationComponent, index: number) => {
        return {
          bounds: location.bounds,
          lat: location.lat,
          lng: location.lng,
          location: location.location,
          location_type: location.location_type,
          place_id: location.place_id,
          distance: index === 0 ? this.searchInput.mile : location.distance,
        };
      });
    },

    computeFilterSummary() {
      const searchType = (() => {
        if (this.searchType === PFilterSearchType.SALES) {
          return 'Properties For Sale';
        } else if (this.searchType === PFilterSearchType.LETTINGS) {
          return 'Properties To Rent';
        }
        return '';
      })();
      const location = (() => {
        if (this.searchInput.keyword) {
          return ` in ${this.searchInput.keyword}`;
        }
        return '';
      })();
      const mile = (() => {
        if (this.searchInput.mile && location) {
          return `, within ${this.searchInput.mile} miles`;
        }
        return '';
      })();

      const locationPart = (() => {
        if (location) {
          return `${searchType}${location}${mile}`;
        } else if (searchType) {
          return `${searchType}`;
        }
        return '';
      })();

      const pricePart = (() => {
        const prices =
          this.searchType === PFilterSearchType.SALES
            ? CriteriaPreferencesConstants.salesPrices
            : CriteriaPreferencesConstants.lettingsPrices;

        const { display: minPrice } = prices.find((price) => price.value === this.filters.min_price) || {
          display: 0,
          value: 0,
        };
        const { display: maxPrice } = prices.find((price) => price.value === this.filters.max_price) || {
          display: 0,
          value: 0,
        };

        if (this.filters.min_price && this.filters.max_price) {
          return `, ${minPrice} - ${maxPrice}`;
        } else if (this.filters.min_price) {
          return `, at least ${minPrice}`;
        } else if (this.filters.max_price) {
          return `, up to ${maxPrice}`;
        }
        return '';
      })();

      const bedroomPart = (() => {
        if (this.filters.min_bedroom && this.filters.max_bedroom) {
          return `, ${this.filters.min_bedroom} - ${this.filters.max_bedroom} bed`;
        } else if (this.filters.min_bedroom) {
          return `, at least ${this.filters.min_bedroom} bed`;
        } else if (this.filters.max_bedroom) {
          return `, up to ${this.filters.max_bedroom} bed`;
        }
        return '';
      })();

      this.filterSummary = `${locationPart}${pricePart}${bedroomPart}`;
    },

    async onSearch() {
      this.advancedSearchConfig.isActive = false;
      this.isExtendedFilterPopupVisible = false;
      this.isLoading = true;
      this.filterCriteria.sort = (() => {
        // by design request for changing sort if min or max price exists in filters
        if (
          this.filterCriteria.sort === PFilterSortOptions.HIGHEST_PRICE &&
          (this.filters.max_price || this.filters.min_price)
        ) {
          return PFilterSortOptions.SUGGESTED;
        }
        return this.filterCriteria.sort;
      })();

      const store = usePropertiesStore();
      await store.fetch(
        FetchModes.SEARCH,
        9,
        this.filterCriteria.sort,
        this.searchType,
        { is_available: this.searchInput.is_available },
        { locations: this.mapLocationsForSearch(), ...this.filters },
      );
      this.isLoading = false;
      this.computeFilterSummary();
      const coreStore = useCoreStore();
      coreStore.updateMetaTagForce('title', this.filterSummary);
    },

    async loadMore() {
      const store = usePropertiesStore();
      await store.fetch(
        FetchModes.LOAD_MORE,
        9,
        this.filterCriteria.sort,
        this.searchType,
        { is_available: this.searchInput.is_available },
        { locations: this.mapLocationsForSearch(), ...this.filters },
      );
    },

    async sort() {
      const store = usePropertiesStore();
      this.isLoading = true;
      await store.fetch(
        FetchModes.SORT,
        9,
        this.filterCriteria.sort,
        this.searchType,
        { is_available: this.searchInput.is_available },
        { locations: this.mapLocationsForSearch(), ...this.filters },
      );
      this.isLoading = false;
    },

    async setupHeadsUpAlerts() {
      const type = (() => {
        if (this.searchType === PFilterSearchType.LETTINGS) {
          return 'rent';
        }
        return 'buy';
      })();

      await this.redirectTo({ path: `/heads-up-alerts/register/${type}` }, true);
    },

    async updateHeadsUpAlerts() {
      if (this.applicant) {
        const applicantKey = this.applicant?.applicant_key;
        await this.redirectTo({ path: `/heads-up-alerts/profiles/${applicantKey}` });
      }
    },
  },
});
</script>

<style scoped>
.n-sort-select {
}

.n-sort-select:after {
  content: '\e92e';
  font-family: 'neuron-common-icons', sans-serif !important;
  font-size: 16px;
  color: var(--brown);
}

.n-sort-select select {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.properties-filter-item {
  height: 55px;
  background-color: var(--white);
}
</style>
