<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-w-full">
      <!-- Üst Başlık (Mobilde solda, büyük ekranlarda ortada) -->
      <div class="tw-mb-5 tw-text-left md:tw-text-center">
        <h1 style="color: var(--c-primary)">
          {{ vars.titleText }}
        </h1>
      </div>
      <div class="tw-mb-5 tw-text-left">
        <h3>{{ vars.secondaryTitleText }}</h3>
      </div>

      <!-- Tüm Şubeler -->
      <div class="tw-space-y-10">
        <div
          v-for="(branch, index) in branches"
          :key="`contact-us-branch-${index}`"
          class="tw-grid tw-grid-cols-1 tw-items-center tw-overflow-hidden xl:tw-grid-cols-2"
          :style="`border-radius: var(--rounded)`"
        >
          <!-- Fotoğraf Alanı -->
          <div class="tw-h-full">
            <LibraryImage
              :src="branch.photo"
              :w-ratio="1414"
              :h-ratio="1034"
              class="tw-h-full tw-w-full tw-object-cover"
            />
          </div>

          <!-- Metin/Sağ Bilgiler Alanı -->
          <!-- Mobilde padding'i kaldırmak için tw-p-0, daha büyük ekranlarda padding devam etsin diye md:tw-p-6, lg:tw-p-10 kullandık -->
          <div class="tw-p-0 md:tw-p-6 lg:tw-p-10">
            <div class="tw-grid tw-grid-cols-1 tw-gap-5 lg:tw-grid-cols-2">
              <!-- Adres / Telefon / E-Posta -->
              <div class="tw-col-span-1 tw-space-y-8">
                <!-- Adres -->
                <div class="tw-items-start">
                  <div class="tw-min-w-9"></div>
                  <div class="tw-w-full tw-space-y-2">
                    <p>Address</p>
                    <span>{{ branch.address }}</span>
                  </div>
                </div>

                <!-- Telefon -->
                <div class="tw-items-start">
                  <div class="tw-min-w-9"></div>
                  <div class="tw-w-full tw-space-y-2">
                    <p>Phone</p>
                    <span>
                      <nuxt-link :to="`tel:${branch.primary_phone}`" class="n-link">
                        {{ branch.primary_phone }}
                      </nuxt-link>
                    </span>
                    <span v-if="branch.secondary_phone">
                      <nuxt-link :to="`tel:${branch.secondary_phone}`" class="n-link">
                        {{ branch.secondary_phone }}
                      </nuxt-link>
                    </span>
                  </div>
                </div>

                <!-- E-Posta -->
                <div class="tw-items-start">
                  <div class="tw-min-w-9"></div>
                  <div class="tw-line-clamp-2 tw-space-y-2">
                    <p>Email</p>
                    <span :title="branch.primary_email" class="scrollbar tw-overflow-x-auto">
                      <nuxt-link :to="`mailto:${branch.primary_email}`" class="n-link">
                        {{ branch.primary_email }}
                      </nuxt-link>
                    </span>
                    <span v-if="branch.secondary_email" class="scrollbar">
                      <nuxt-link :to="`mailto:${branch.secondary_email}`" class="n-link">
                        {{ branch.secondary_email }}
                      </nuxt-link>
                    </span>
                  </div>
                </div>

                <!-- Sosyal Medya İkonları -->
                <div class="tw-items-start">
                  <div class="tw-min-w-9"></div>
                  <div class="tw-w-full tw-space-y-2">
                    <p>Social</p>
                    <div class="tw-flex tw-gap-3">
                      <!-- Facebook -->
                      <a :href="branch.facebook_url || '#'" class="n-link tw-group" target="_blank">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="tw-fill-transparent tw-transition-all tw-duration-300 group-hover:tw-fill-[var(--c-secondary)]"
                        >
                          <path
                            d="M31.75 16C31.75 24.6985 24.6985 31.75 16 31.75C7.30151 31.75 0.25 24.6985 0.25 16C0.25 7.30151 7.30151 0.25 16 0.25C24.6985 0.25 31.75 7.30151 31.75 16Z"
                            stroke="#F46F2C"
                            stroke-width="0.5"
                          />
                          <path
                            d="M17.1693 16.875H18.6276L19.2109 14.5417H17.1693V13.375C17.1693 12.7742 17.1693 12.2084 18.3359 12.2084H19.2109V10.2484C19.0208 10.2233 18.3027 10.1667 17.5444 10.1667C15.9606 10.1667 14.8359 11.1333 14.8359 12.9084V14.5417H13.0859V16.875H14.8359V21.8334H17.1693V16.875Z"
                            fill="#F46F2C"
                            class="tw-duration-300 group-hover:tw-fill-[#fff]"
                          />
                        </svg>
                      </a>

                      <!-- Instagram -->
                      <a :href="branch.instagram_url || '#'" class="n-link tw-group" target="_blank">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="tw-fill-transparent tw-transition-all tw-duration-300 group-hover:tw-fill-[var(--c-secondary)]"
                        >
                          <circle cx="16" cy="16" r="15.75" stroke="#F46F2C" stroke-width="0.5" />
                          <path
                            d="M13.5474 10.1667H18.4474C20.3141 10.1667 21.8307 11.6834 21.8307 13.55V18.45C21.8307 19.3473 21.4743 20.2079 20.8398 20.8424C20.2053 21.4769 19.3447 21.8334 18.4474 21.8334H13.5474C11.6807 21.8334 10.1641 20.3167 10.1641 18.45V13.55C10.1641 12.6527 10.5205 11.7921 11.155 11.1576C11.7895 10.5231 12.6501 10.1667 13.5474 10.1667ZM13.4307 11.3334C12.8738 11.3334 12.3396 11.5546 11.9458 11.9484C11.552 12.3423 11.3307 12.8764 11.3307 13.4334V18.5667C11.3307 19.7275 12.2699 20.6667 13.4307 20.6667H18.5641C19.121 20.6667 19.6552 20.4454 20.049 20.0516C20.4428 19.6578 20.6641 19.1236 20.6641 18.5667V13.4334C20.6641 12.2725 19.7249 11.3334 18.5641 11.3334H13.4307ZM19.0599 12.2084C19.2533 12.2084 19.4387 12.2852 19.5755 12.4219C19.7122 12.5587 19.7891 12.7441 19.7891 12.9375C19.7891 13.1309 19.7122 13.3164 19.5755 13.4531C19.4387 13.5899 19.2533 13.6667 19.0599 13.6667C18.8665 13.6667 18.681 13.5899 18.5443 13.4531C18.4076 13.3164 18.3307 13.1309 18.3307 12.9375C18.3307 12.7441 18.4076 12.5587 18.5443 12.4219C18.681 12.2852 18.8665 12.2084 19.0599 12.2084ZM15.9974 13.0834C16.7709 13.0834 17.5128 13.3906 18.0598 13.9376C18.6068 14.4846 18.9141 15.2265 18.9141 16C18.9141 16.7736 18.6068 17.5154 18.0598 18.0624C17.5128 18.6094 16.7709 18.9167 15.9974 18.9167C15.2238 18.9167 14.482 18.6094 13.935 18.0624C13.388 17.5154 13.0807 16.7736 13.0807 16C13.0807 15.2265 13.388 14.4846 13.935 13.9376C14.482 13.3906 15.2238 13.0834 15.9974 13.0834ZM15.9974 14.25C15.5333 14.25 15.0881 14.4344 14.76 14.7626C14.4318 15.0908 14.2474 15.5359 14.2474 16C14.2474 16.4641 14.4318 16.9093 14.76 17.2375C15.0881 17.5656 15.5333 17.75 15.9974 17.75C16.4615 17.75 16.9066 17.5656 17.2348 17.2375C17.563 16.9093 17.7474 16.4641 17.7474 16C17.7474 15.5359 17.563 15.0908 17.2348 14.7626C16.9066 14.4344 16.4615 14.25 15.9974 14.25Z"
                            fill="#F46F2C"
                            class="tw-duration-300 group-hover:tw-fill-[#fff]"
                          />
                        </svg>
                      </a>

                      <!-- LinkedIn -->
                      <a :href="branch.linkedin_url || '#'" class="n-link tw-group" target="_blank">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          class="tw-fill-transparent tw-transition-all tw-duration-300 group-hover:tw-fill-[var(--c-secondary)]"
                        >
                          <circle cx="16" cy="16" r="15.75" stroke="#F46F2C" stroke-width="0.5" />
                          <path
                            d="M13.0521 11.9166C13.0519 12.2261 12.9289 12.5227 12.71 12.7414C12.4911 12.9601 12.1943 13.0829 11.8848 13.0827C11.5754 13.0826 11.2787 12.9595 11.06 12.7406C10.8414 12.5217 10.7186 12.2249 10.7188 11.9155C10.7189 11.6061 10.842 11.3094 11.0609 11.0907C11.2798 10.872 11.5766 10.7492 11.886 10.7494C12.1954 10.7495 12.4921 10.8726 12.7108 11.0915C12.9295 11.3104 13.0522 11.6072 13.0521 11.9166ZM13.0871 13.9466H10.7538V21.25H13.0871V13.9466ZM16.7738 13.9466H14.4521V21.25H16.7504V17.4175C16.2504 15.2825 19.5329 15.0841 19.5329 17.4175V21.25H21.8371V16.6241C21.8371 13.025 17.7188 13.1591 16.7504 14.9266L16.7738 13.9466Z"
                            fill="#F46F2C"
                            class="tw-duration-300 group-hover:tw-fill-[#fff]"
                          />
                        </svg>
                      </a>

                      <!-- TikTok -->
                      <a :href="branch.tiktok_url || '#'" class="n-link tw-group" target="_blank">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          class="tw-fill-transparent tw-transition-all tw-duration-300 group-hover:tw-fill-[var(--c-secondary)]"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="16" cy="16" r="15.75" stroke="#F46F2C" stroke-width="0.5" />
                          <path
                            d="M18.6864 12.395C18.2876 11.9398 18.0679 11.3552 18.068 10.75H16.2655V17.9833C16.2516 18.3748 16.0864 18.7455 15.8046 19.0175C15.5227 19.2896 15.1464 19.4416 14.7547 19.4417C13.9264 19.4417 13.238 18.765 13.238 17.925C13.238 16.9217 14.2064 16.1692 15.2039 16.4783V14.635C13.1914 14.3667 11.4297 15.93 11.4297 17.925C11.4297 19.8675 13.0397 21.25 14.7489 21.25C16.5805 21.25 18.068 19.7625 18.068 17.925V14.2558C18.7989 14.7807 19.6765 15.0624 20.5764 15.0608V13.2583C20.5764 13.2583 19.4797 13.3108 18.6864 12.395Z"
                            fill="#F46F2C"
                            class="tw-duration-300 group-hover:tw-fill-[#fff]"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Çalışma Saatleri -->
              <div>
                <div class="tw-items-start">
                  <div class="tw-w-9"></div>
                  <div class="tw-w-full">
                    <p>Time</p>
                    <span>Opening Hours</span>
                    <div>
                      <!-- Eğer tek satırlık "general_office_hour" varsa -->
                      <div v-if="branch.general_office_hour" class="tw-flex tw-justify-between">
                        <p>General:</p>
                        {{ branch.general_office_hour }}
                      </div>

                      <!-- Günlere göre ayırma -->
                      <div v-else class="tw-mt-4 tw-space-y-4 tw-text-[#545454]">
                        <div class="tw-flex tw-justify-between">
                          <p>Monday:</p>
                          <p>{{ branch.monday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Tuesday:</p>
                          <p>{{ branch.tuesday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Wednesday:</p>
                          <p>{{ branch.wednesday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Thursday:</p>
                          <p>{{ branch.thursday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Friday:</p>
                          <p>{{ branch.friday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Saturday:</p>
                          <p>{{ branch.saturday }}</p>
                        </div>
                        <div class="tw-flex tw-justify-between">
                          <p>Sunday:</p>
                          <p>{{ branch.sunday }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Çalışma Saatleri -->
            </div>
          </div>
          <!-- /Metin/Sağ Bilgiler Alanı -->
        </div>
        <!-- /Tek Şube Kapsayıcı -->
      </div>
      <!-- /Tüm Şubeler -->
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContactUs',
  mixins: [ComponentMixin],

  data() {
    return {
      form: null,
    };
  },

  computed: {
    branches() {
      return this.groupedVariables.branches;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },
});
</script>

<style scoped>
.social_icons {
  border-radius: 100% !important;
  padding: 10px !important;
}

.scrollbar::-webkit-scrollbar {
  height: 3px;
  width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #cbdceb;
  border-radius: 40px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--c-primary);
  border-radius: 40px;
}

@media screen and (min-width: 1024px) {
  .scrollbar::-webkit-scrollbar {
    height: 5px;
  }
}
</style>
