<template>
  <div v-if="variants.v1 === variant" class="nc-text tw-pt-12 md:tw-pt-0" style="background-color: var(--white)">
    <section v-if="property" class="section-product-content pb-5" style="background-color: var(--white)">
      <div class="page-padding">
        <div class="container-large">
          <div class="padding-top padding-xhuge">
            <div class="row g-0">
              <div id="w-node-a1202a3f-2ddc-9dfb-6534-95f035b2fc36-89606d9b" class="col-lg-6">
                <div v-if="firstImage" class="image-wrapper image-container n-cursor-pointer">
                  <library-image
                    :src="firstImage?.background"
                    @click="onClickedImage(firstImage)"
                    :blur="imageBlurFilter"
                  >
                  </library-image>
                  <div class="first-image-overlay-item">
                    <div
                      v-if="medias"
                      style="background-color: var(--white); color: var(--dark-brown)"
                      class="px-2 py-1"
                    >
                      1/{{ medias.length }}
                    </div>
                  </div>
                </div>
                <div class="row g-0 pt-1">
                  <div v-if="secondImage" class="col-6 pe-1 n-cursor-pointer">
                    <library-image
                      :src="secondImage?.background"
                      @click="onClickedImage(secondImage)"
                      :blur="imageBlurFilter"
                    >
                    </library-image>
                  </div>
                  <div v-if="thirdImage" class="col-6 ps-1 n-cursor-pointer">
                    <library-image
                      :src="thirdImage?.background"
                      @click="onClickedImage(thirdImage)"
                      :blur="imageBlurFilter"
                    >
                    </library-image>
                  </div>
                </div>
              </div>
              <div id="w-node-d0e446c1-f65a-c0b2-1cff-f23737d9485d-89606d9b" class="col-lg-6 ps-lg-5 pt-4 pt-lg-0">
                <div class="margin-bottom margin-medium">
                  <div class="d-flex flex-row justify-content-between mb-3">
                    <div>
                      <div v-if="property.custom_property_status_title" class="property-image-badge">
                        {{ property.custom_property_status_title }}
                      </div>
                    </div>
                    <div>
                      {{ property.online_date_from_now }}
                    </div>
                  </div>
                  <h1 class="heading-small">
                    {{ property.advert_heading }}
                  </h1>
                  <h2 class="heading-xsmall mt-2 text-capitalize">
                    {{ property.display_address }}
                  </h2>
                  <div v-if="property.price" class="mt-4">
                    <div class="text-size-small neuron-capitalise">
                      {{ property.price.type.name }}
                    </div>
                    <div class="price">
                      {{ property.price.price }}
                    </div>
                  </div>
                  <div v-if="!property.is_locked" class="d-flex flex-row justify-content-between mt-5">
                    <div v-if="property.bedroom" class="d-flex flex-column">
                      <div class="property-criteria__label">BEDROOM</div>
                      <div class="property-criteria__value">
                        {{ property.bedroom }}
                      </div>
                    </div>
                    <div v-if="property.bathroom" class="d-flex flex-column">
                      <div class="property-criteria__label">BATHROOM</div>
                      <div class="property-criteria__value">
                        {{ property.bathroom }}
                      </div>
                    </div>
                    <div v-if="property.reception" class="d-flex flex-column">
                      <div class="property-criteria__label">RECEPTION</div>
                      <div class="property-criteria__value">
                        {{ property.reception }}
                      </div>
                    </div>
                  </div>
                  <div class="tw-mt-8 tw-grid tw-grid-cols-8 tw-gap-y-5 xl:tw-gap-x-6 xl:tw-gap-y-0">
                    <div v-if="!property.is_locked" class="tw-col-span-8 xl:tw-col-span-3">
                      <nc-button label="ARRANGE A VIEWING" :height="55" @on-click="onSendLead()"></nc-button>
                    </div>
                    <div class="tw-col-span-8 xl:tw-col-span-4">
                      <nuxt-link
                        to="/valuation"
                        data-w-id="be7ec0df-c1d4-01e9-596d-897784db125e"
                        :external="true"
                        class="button outline w-inline-block"
                        style="color: var(--brown)"
                      >
                        <div>What’s MY property worth?</div>
                        <div
                          style="
                            -webkit-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
                              rotateZ(0) skew(0, 0);
                            -moz-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            -ms-transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                            transform: translate3d(-102%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0)
                              skew(0, 0);
                          "
                          class="hover-underline"
                        ></div>
                      </nuxt-link>
                    </div>
                  </div>

                  <div class="tw-mt-8 tw-grid tw-grid-cols-8 tw-gap-y-5 lg:tw-gap-x-6 lg:tw-gap-y-0">
                    <div v-if="property.branch_phone_number" class="tw-col-span-8 tw-flex tw-flex-col lg:tw-col-span-3">
                      <div style="letter-spacing: 5px" class="text-size-tiny">CALL NOW</div>
                      <a :href="`tel:${property.branch_phone_number}`" class="tw-text-base tw-font-extralight">
                        {{ property.branch_phone_number }}
                      </a>
                    </div>
                    <div v-if="property.branch_email" class="tw-col-span-8 tw-flex tw-flex-col lg:tw-col-span-4">
                      <div style="letter-spacing: 5px" class="text-size-tiny">EMAIL</div>
                      <a :href="`mailto:${property.branch_email}`" class="tw-text-base tw-font-extralight">
                        {{ property.branch_email }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="property" class="section-product-content pb-5" style="background-color: var(--white)">
      <div class="page-padding">
        <div class="container-large">
          <div v-if="!property.is_locked" class="row g-0 pt-5">
            <div
              v-if="property"
              class="tw-grid tw-grid-cols-12"
              :class="property?.featured_video ? 'col-lg-6 col-12' : 'col-12'"
            >
              <div
                v-for="(feature, key) in property.features"
                :key="`property-feature-${key}`"
                class="text-weight-light mb-5 d-flex flex-row align-items-start pe-4"
                style="color: var(--brown--secondary); font-size: 16px"
                :class="
                  property?.featured_video ? 'tw-col-span-12 lg:tw-col-span-6' : 'tw-col-span-12 lg:tw-col-span-4'
                "
              >
                <div class="me-4">
                  <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :d="icons.check" stroke="#AF624E" stroke-width="3" />
                  </svg>
                </div>
                <div>
                  {{ feature }}
                </div>
              </div>
            </div>
            <div v-if="property?.featured_video" class="col-lg-6 col-12 d-flex justify-content-end n-cursor-pointer">
              <lazy-theme2-components-video-popup
                :component="component"
                :video-source="property.featured_video.mediaLink"
              />
              <div v-if="false" class="image-container">
                <div class="overlay-item">
                  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path :d="icons.bigPlayButton" fill="white" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="property.is_locked">
            <library-login />
          </div>
        </div>
      </div>
    </section>
    <property-detail-tabs
      v-if="property && !property.is_locked"
      :property="property"
      :component="component"
      :marker-color="markerColor"
    />
    <full-screen-gallery
      v-if="fullScreenPopupConfig.is_active && activeMedia && medias && !property.is_locked"
      v-model:active-media-index="activeMediaIndex"
      :component="component"
      :active-media="activeMedia"
      :count-of-medias="medias.length"
      @close="closeLightbox()"
    />
    <popup v-show="!property.is_locked" :config="sendLeadPopupConfig" custom-width="800px">
      <template #content>
        <send-lead
          v-if="sendLeadPopupConfig.isActive"
          :property="property"
          @on-success="onSuccess"
          @close="sendLeadPopupConfig.isActive = false"
        />
      </template>
    </popup>
    <popup v-show="!property.is_locked" :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </div>

  <section
    v-else-if="variants.v2 === variant"
    class="n-section-primary !tw-pt-36"
    style="background-color: var(--white)"
  >
    <div class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-y-10 md:tw-gap-x-10 lg:tw-gap-y-28">
      <div v-if="property" class="tw-col-span-12" style="background-color: var(--white)">
        <nuxt-link
          to="/properties"
          :external="true"
          class="n-link tw-mb-10 tw-flex tw-items-center tw-gap-6 tw-self-start tw-text-[var(--c-secondary)]"
        >
          <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.07545 7.91476L4.37536 11.2147L5.2002 10.3898L2.3127 7.50234L5.2002 4.61484L4.37536 3.79001L1.07545 7.08993C0.966087 7.19932 0.904654 7.34766 0.904654 7.50234C0.904654 7.65702 0.966087 7.80537 1.07545 7.91476Z"
              fill="#F46F2C"
            />
          </svg>
          Back to all properties
        </nuxt-link>
        <div class="row g-0 lg:tw-flex lg:tw-w-full lg:tw-flex-row-reverse">
          <div id="w-node-a1202a3f-2ddc-9dfb-6534-95f035b2fc36-89606d9b" class="col-lg-6">
            <div
              v-if="firstImage"
              class="image-wrapper image-container tw-overflow-hidden"
              style="border-radius: var(--rounded)"
            >
              <library-image :src="firstImage?.background" @click="onClickedImage(firstImage)" :blur="imageBlurFilter">
              </library-image>
              <div class="first-image-overlay-item">
                <div v-if="medias" style="background-color: var(--white)" class="px-2 py-1">1/{{ medias.length }}</div>
              </div>
            </div>
            <div class="pt-1 tw-flex tw-w-full tw-flex-nowrap tw-justify-between tw-gap-1 tw-overflow-hidden">
              <div
                v-if="secondImage"
                class="col-4 n-cursor-pointer tw-flex-[33%] tw-overflow-hidden"
                style="border-radius: var(--rounded)"
              >
                <library-image
                  :src="secondImage?.background"
                  @click="onClickedImage(secondImage)"
                  :blur="imageBlurFilter"
                  :h-ratio="1"
                  :w-ratio="1.5"
                >
                </library-image>
              </div>
              <div
                v-if="thirdImage"
                class="col-4 n-cursor-pointer tw-flex-[33%] tw-overflow-hidden"
                style="border-radius: var(--rounded)"
              >
                <library-image
                  :src="thirdImage?.background"
                  @click="onClickedImage(thirdImage)"
                  :blur="imageBlurFilter"
                  :h-ratio="1"
                  :w-ratio="1.5"
                >
                </library-image>
              </div>
              <div
                v-if="thirdImage"
                class="col-4 n-cursor-pointer tw-flex-[33%] tw-overflow-hidden"
                style="border-radius: var(--rounded)"
              >
                <library-image
                  :src="thirdImage?.background"
                  @click="onClickedImage(thirdImage)"
                  :blur="imageBlurFilter"
                  :h-ratio="1"
                  :w-ratio="1.5"
                >
                </library-image>
              </div>
            </div>
          </div>
          <div
            id="w-node-d0e446c1-f65a-c0b2-1cff-f23737d9485d-89606d9b"
            class="col-lg-6 ps-lg-5 pt-4 pt-lg-0 lg:!tw-pl-0 lg:tw-pr-5"
          >
            <div class="margin-bottom margin-medium">
              <div class="d-flex flex-row justify-content-between mb-3">
                <div>
                  <div v-if="property.custom_property_status_title" class="property-image-badge">
                    {{ property.custom_property_status_title }}
                  </div>
                </div>
              </div>
              <h3 class="tw-text-[var(--c-primary)]">
                {{ property.advert_heading }}
              </h3>
              <p>
                {{ property.display_address }}
              </p>
              <div v-if="property.price" class="mt-4">
                <span class="">
                  {{ property.price.type.name }}
                </span>
                <h6>
                  {{ property.price.price }}
                </h6>
              </div>
              <div
                v-if="!property.is_locked"
                class="d-flex flex-row justify-content-between mt-5 lg:!tw-justify-start lg:tw-gap-8"
              >
                <div v-if="property.bedroom" class="d-flex flex-column">
                  <small>BEDROOM</small>
                  <span>
                    {{ property.bedroom }}
                  </span>
                </div>
                <div v-if="property.bathroom" class="d-flex flex-column">
                  <small>BATHROOM</small>
                  <span>
                    {{ property.bathroom }}
                  </span>
                </div>
                <div v-if="property.reception" class="d-flex flex-column">
                  <small>RECEPTION</small>
                  <span>
                    {{ property.reception }}
                  </span>
                </div>
              </div>
              <div class="primary-property tw-mt-8 tw-grid tw-grid-cols-8 tw-gap-y-5 xl:tw-gap-x-6 xl:tw-gap-y-0">
                <div v-if="!property.is_locked" class="tw-col-span-8 xl:tw-col-span-3">
                  <nc-button
                    :class="`tw-w-full !tw-justify-start md:tw-w-auto`"
                    label="BOOK A VIEWING"
                    @on-click="onSendLead()"
                  >
                  </nc-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="property" class="tw-col-span-12 !tw-pt-0" style="background-color: var(--white)">
        <div v-if="!property.is_locked" class="row g-0 tw-w-full tw-gap-12">
          <div
            v-if="property?.featured_video"
            class="col-lg-6 col-12 d-flex justify-content-end n-cursor-pointer lg:tw-w-[47%]"
          >
            <library-iframe :src="property.featured_video.mediaLink" />
            <div v-if="false" class="image-container">
              <div class="overlay-item">
                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path :d="icons.bigPlayButton" fill="white" />
                </svg>
              </div>
            </div>
          </div>
          <div
            v-if="property"
            class="tw-grid tw-grid-cols-12 tw-gap-y-6"
            :class="property?.featured_video ? 'col-lg-6 col-12 lg:tw-w-[47%]' : 'col-12'"
          >
            <div
              v-for="(feature, key) in property.features"
              :key="`property-feature-${key}`"
              class="tw-flex tw-flex-row tw-gap-3"
              :class="property?.featured_video ? 'tw-col-span-12 lg:tw-col-span-6' : 'tw-col-span-12 lg:tw-col-span-4'"
            >
              <div>
                <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path :d="icons.check" stroke="var(--c-primary)" stroke-width="3" />
                </svg>
              </div>
              <p>
                {{ feature }}
              </p>
            </div>
          </div>
        </div>
        <div v-else-if="property.is_locked">
          <library-login />
        </div>
      </div>
      <div class="tw-col-span-12 tw-flex tw-flex-col tw-gap-y-6 lg:tw-col-span-7 lg:tw-gap-y-10 xl:tw-col-span-8">
        <property-detail-tabs
          v-if="property && !property.is_locked"
          :property="property"
          :component="component"
          :marker-color="markerColor"
          version="v2"
        />
        <property-detail-tabs
          v-if="property && !property.is_locked"
          :property="property"
          :component="component"
          :marker-color="markerColor"
          version="v3"
        />
      </div>
      <div
        v-if="sidebarComponents && sidebarComponents.length"
        class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4"
      >
        <div>
          <available-components-for-sidebar
            key="property-detail-sidebar"
            key-prefix="property-detail-sidebar"
            :specific-components="sidebarComponents"
          />
        </div>
      </div>
    </div>

    <full-screen-gallery
      v-if="fullScreenPopupConfig.is_active && activeMedia && medias && !property.is_locked"
      v-model:active-media-index="activeMediaIndex"
      :component="component"
      :active-media="activeMedia"
      :count-of-medias="medias.length"
      @close="closeLightbox()"
    />
    <popup v-show="!property.is_locked" :config="sendLeadPopupConfig" custom-width="800px">
      <template #content>
        <send-lead
          v-if="sendLeadPopupConfig.isActive"
          :property="property"
          @on-success="onSuccess"
          @close="sendLeadPopupConfig.isActive = false"
        />
      </template>
    </popup>
    <popup v-show="!property.is_locked" :config="successModalConfig" @close="onSuccessModalClosed()">
      <template #content>
        <success :text="successPopupText" />
      </template>
    </popup>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { PropertyMixin } from '~/units/properties/mixins/property.mixin';
import { FullScreenPopupMixin } from '~/units/core/mixins/fullScreenPopupMixin';

import FullScreenGallery from '~/components/common/FullScreenGallery.vue';
import PropertyDetailTabs from '~/components/theme2/properties/PropertyDetailTabs.vue';
import NcButton from '~/components/common/inputs/NcButton.vue';
import Popup from '~/components/common/popup/Popup.vue';
import SendLead from '~/components/common/leads/SendLead.vue';
import Success from '~/components/common/popup/Success.vue';
import { getImagesByCount } from '~/units/properties/store';
import type { Component } from '~/types';
import { usePropertiesStore } from '~/units/properties/stores';
import AvailableComponentsForSidebar from '~/components/theme2/AvailableComponentsForSidebar.vue';
import { type FullScreenModalType, FullScreenModalTypes } from '~/units/core/types';

export default defineNuxtComponent({
  name: 'PropertyDetailTheme2',

  mixins: [ComponentMixin, PropertyMixin, FullScreenPopupMixin],

  components: {
    Success,
    SendLead,
    Popup,
    NcButton,
    PropertyDetailTabs,
    FullScreenGallery,
    AvailableComponentsForSidebar,
  },

  async setup(props: { component: Component }) {
    const store = usePropertiesStore();
    await store.fetchSingleProperty(props.component);
  },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    imageBlurFilter() {
      if (this.property?.is_locked) {
        return '14px';
      }
      return undefined;
    },

    sidebarComponents(): Component[] {
      if (this.currentPage && this.currentPage.sidebar) {
        return this.currentPage.sidebar;
      }
      return [];
    },

    fullScreenPopupType(): FullScreenModalType {
      return FullScreenModalTypes.GALLERY;
    },

    featuredImages() {
      if (this.property) {
        return getImagesByCount(this.property, 3);
      }
      return [];
    },

    firstImage() {
      return this.featuredImages[0];
    },

    secondImage() {
      return this.featuredImages[1];
    },

    thirdImage() {
      return this.featuredImages[2];
    },

    fourthImage() {
      return this.featuredImages[3];
    },

    markerColor() {
      return this.variant === this.variants.v1 ? this.colors['brown--secondary'] : this.colors.primary;
    },

    medias() {
      return this.property?.medias;
    },
  },

  methods: {},

  watch: {},
});
</script>

<style scoped>
.price {
  color: var(--brown);
  font-size: 1.5rem;
}

.property-criteria__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
}

.property-criteria__value {
  color: var(--dark-brown);
}

.image-container {
  position: relative;
}

.image-container img {
  display: block;
}

.overlay-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.first-image-overlay-item {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;
  transform: translate(-50%, -50%);
  text-align: right;
}

.property-image-badge {
  background-color: var(--dark-brown);
  color: var(--white);
  padding: 10px;
  font-weight: bold;
}
</style>
