<template>
  <section
    v-if="variants.v1 === variant"
    class="section-services-content content-box pt-5 lg:tw-pb-12"
    :style="`background-color: var(--${vars.backgroundColor})`"
  >
    <div class="page-padding lg:tw-pt-6">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div
            class="why-box d-flex justify-content-between align-items-center"
            :class="{ 'flex-row-reverse': vars.isReverseNumber === 1 }"
            style="gap: 5rem"
          >
            <div class="w-100 image-wrapper">
              <NuxtImg
                v-if="vars.isImageNumber === 1"
                :src="vars.photoImage"
                loading="lazy"
                alt=""
                quality="60"
                class="cover-image"
              />
              <video-popup v-else :component="component" :video-source="vars.videoSourceText" />
            </div>
            <div class="w-100">
              <h2 class="heading-medium">{{ vars.videoTitleText }}</h2>
              <div class="margin-top margin-small">
                <div class="max-width-full">
                  <p class="text-size-tiny text-weight-light">
                    {{ vars.videoDescText }}
                  </p>
                </div>
              </div>
              <div v-if="vars.buttonUrl" class="margin-top margin-medium">
                <div>
                  <nuxt-link
                    data-w-id="bd454024-605c-912a-bc49-318b72174c6f"
                    :external="true"
                    style="width: auto !important; display: inline-block !important"
                    :to="vars.buttonUrl"
                    class="button w-inline-block"
                  >
                    <div class="button-label text-weight-light">
                      {{ vars.buttonText }}
                    </div>
                    <div style="height: 0%" class="overlay"></div>
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary lg:tw-pb-12"
    :style="`background-color: var(--${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-gap-6 lg:tw-flex-row lg:tw-gap-16"
      :class="{ 'flex-row-reverse': vars.isReverseNumber === 1 }"
    >
      <div class="w-100">
        <NuxtImg
          v-if="vars.isImageNumber === 1"
          :src="vars.photoImage"
          :style="{ borderRadius: 'var(--rounded)', overflow: 'hidden' }"
        />
        <video-popup
          v-else
          :component="component"
          :video-source="vars.videoSourceText"
          :style="{ borderRadius: 'var(--rounded)', overflow: 'hidden' }"
        />
      </div>
      <div class="w-100">
        <h2 class="tw-mb-6" :style="{ color: `var(--${vars.titleColor})` }">
          {{ vars.titleText }}
        </h2>
        <div class="tw-flex tw-flex-col">
          <div class="max-width-full tw-flex tw-flex-col tw-gap-2">
            <p
              v-for="({ description }, index) in descriptions"
              :key="`contentbox-description-${index}`"
              :style="{ color: `var(--${vars.textColor})` }"
            >
              {{ description }}
            </p>
          </div>
          <div class="tw-mt-6 tw-flex tw-flex-wrap tw-justify-start tw-gap-3">
            <nuxt-link
              v-for="(button, index) in buttons"
              :key="`button-${index}`"
              :class="[
                buttons.length >= 2 ? 'tw-w-full md:tw-w-auto' : 'tw-w-auto',
                `n-${button.button_type}`,
                'tw-uppercase',
              ]"
              :to="button.button_link"
            >
              {{ button.button_label }}
              <span class="btn-arrow"
                ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section
    v-if="variants.v3 === variant"
    class="n-section-primary lg:tw-pb-12"
    :style="{ 'background-color': `var(--${vars.backgroundColor})` }"
  >
    <div class="tw-flex tw-flex-col-reverse tw-items-center tw-gap-6 lg:tw-flex-row lg:tw-gap-16">
      <div class="tw-w-full lg:tw-w-7/12">
        <h2 :style="{ color: `var(--${vars.titleColor})` }">
          {{ vars.titleText }}
        </h2>
        <div class="tw-flex tw-flex-col">
          <div class="max-width-full tw-flex tw-flex-col tw-gap-2">
            <p
              v-for="({ description }, index) in descriptions"
              :key="`contentbox-description-${index}`"
              :style="{ color: `var(--${vars.textColor})` }"
            >
              {{ description }}
            </p>
          </div>
          <div class="tw-mt-6 tw-flex tw-flex-col tw-gap-3 lg:tw-flex-row lg:tw-flex-wrap lg:tw-justify-start">
            <nuxt-link
              v-for="(button, index) in buttons"
              :key="`button-${index}`"
              :class="['tw-w-full lg:tw-w-auto', `n-${button.button_type}`, 'tw-text-center tw-uppercase']"
              :to="button.button_link"
            >
              {{ button.button_label }}
              <span class="btn-arrow">
                <svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>
          </div>
        </div>
      </div>

      <div class="tw-w-full lg:tw-w-5/12">
        <NuxtImg
          v-if="vars.isImageNumber === 1"
          :src="vars.photoImage"
          :style="{ borderRadius: 'var(--rounded)', overflow: 'hidden' }"
          class="tw-h-auto tw-w-full"
        />
        <video-popup
          v-else
          :component="component"
          :video-source="vars.videoSourceText"
          :style="{ borderRadius: 'var(--rounded)', overflow: 'hidden' }"
          class="tw-h-auto tw-w-full"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import VideoPopup from './VideoPopup.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBoxTheme2',

  mixins: [ComponentMixin],
  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  components: { VideoPopup },

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
        v3: 'v3',
      },
    };
  },
});
</script>

<style scoped>
.cover-image {
  max-height: 500px;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 991px) {
  .why-box {
    flex-wrap: wrap;
    gap: 1rem !important;
  }
  .image-wrapper {
    grid-area: 1 / 1 / 2 / 4 !important;
  }
}

::v-deep(.video-cover iframe) {
  height: 380px !important;
}
</style>
