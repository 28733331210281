<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
      <div class="tw-mb-12 tw-flex tw-flex-wrap tw-justify-start tw-self-start tw-text-start lg:tw-gap-4">
        <h1 :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h1>
      </div>

      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        :clickable="true"
        :breakpoints="{
          320: { slidesPerView: 1, spaceBetween: 20 },
          1024: { slidesPerView: 2, spaceBetween: 24 },
          1280: { slidesPerView: 3, spaceBetween: 24 },
        }"
        @swiper="onSwiper"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(video, index) in videos" :key="`singletestimonials-video-${index}`" class="!tw-h-auto">
          <MotionGroup
            is="div"
            preset="fadeVisible"
            :duration="400"
            class="n-card tw-cursor-pointer tw-bg-white tw-p-6"
            style="border-radius: var(--rounded)"
          >
            <div class="tw-flex tw-flex-col tw-gap-4" :style="{ minHeight: '380px' }">
              <div class="tw-relative tw-w-full tw-pt-[56.25%]" style="aspect-ratio: 16/10">
                <div
                  v-if="video.photo && video.video"
                  class="tw-absolute tw-inset-0 tw-overflow-hidden"
                  style="border-radius: var(--rounded)"
                >
                  <library-iframe
                    :src="video.video"
                    class="tw-h-full tw-w-full"
                    style="border-radius: var(--rounded)"
                    :h-ratio="10"
                    :w-ratio="16"
                  />
                </div>
              </div>
              <div class="tw-flex tw-flex-grow tw-flex-col tw-gap-4">
                <div class="testimonial-stars">
                  <span class="stars-wrapper tw-flex tw-flex-row">
                    <svg
                      v-for="(i, key) in video.rating"
                      :key="`rating-${key}`"
                      width="24"
                      height="24"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="tw-mr-1"
                    >
                      <path
                        d="M8.7375 33L11.175 22.4625L3 15.375L13.8 14.4375L18 4.5L22.2 14.4375L33 15.375L24.825 22.4625L27.2625 33L18 27.4125L8.7375 33Z"
                        fill="#F46F2C"
                      />
                    </svg>
                  </span>
                </div>

                <h4 class="tw-line-clamp-2 tw-h-16" :style="`color: var(--c-${vars.titleColor});`">
                  {{ video.title }}
                </h4>
                <span>{{ video.author }}</span>
              </div>
            </div>
          </MotionGroup>
        </SwiperSlide>
      </Swiper>

      <div class="tw-mt-10">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v7"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-flex-col tw-items-center">
      <div class="tw-mb-12 tw-flex tw-flex-wrap tw-justify-start tw-self-start tw-text-start lg:tw-gap-4">
        <h1 :style="`color: var(--c-${vars.titleColor});`">
          {{ vars.titleText }}
        </h1>
      </div>

      <Swiper
        :modules="[SwiperPagination]"
        @slideChange="onSlideChange"
        :pagination="false"
        :slides-per-view="1"
        :space-between="20"
        :clickable="true"
        :breakpoints="{
          320: { slidesPerView: 1, spaceBetween: 20 },
          1024: { slidesPerView: 2, spaceBetween: 24 },
          1280: { slidesPerView: 3, spaceBetween: 24 },
        }"
        @swiper="onSwiper"
        class="tw-w-full"
      >
        <SwiperSlide v-for="(video, index) in videos" :key="`singletestimonials-video-${index}`" class="!tw-h-auto">
          <MotionGroup
            is="div"
            preset="fadeVisible"
            :duration="400"
            class="n-card tw-cursor-pointer tw-bg-white tw-p-4"
            style="border-radius: var(--rounded)"
          >
            <div class="tw-flex tw-flex-col tw-gap-4" :style="{ minHeight: '380px' }">
              <div class="tw-relative tw-w-full tw-pt-[56.25%]" style="aspect-ratio: 16/10">
                <div
                  v-if="video.photo && video.video"
                  class="tw-absolute tw-inset-0 tw-overflow-hidden"
                  style="border-radius: var(--rounded)"
                >
                  <library-iframe
                    :src="video.video"
                    class="tw-h-full tw-w-full"
                    style="border-radius: var(--rounded)"
                    :h-ratio="10"
                    :w-ratio="16"
                  />
                </div>
              </div>
              <div class="tw-flex tw-flex-grow tw-flex-col tw-gap-4">
                <h4 class="tw-line-clamp-2 tw-h-16" :style="`color: var(--c-${vars.titleColor});`">
                  {{ video.title }}
                </h4>
                <span>{{ video.author }}</span>
              </div>
            </div>
          </MotionGroup>
        </SwiperSlide>
      </Swiper>

      <div class="tw-mt-10">
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v7"
          :canPaginateBack="canPaginateBack"
          :canPaginateNext="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import DescriptionBanner from '~/components/theme6/components/DescriptionBanner.vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'VideoBox',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      isVisible: false,
      currentVideoUrl: '',
      swiper: null,
      canPaginateBack: false,
      canPaginateNext: true,
      testimonials: [] as { [key: string]: string | number | null }[],
    };
  },

  computed: {
    videos() {
      return this.groupedVariables.videos;
    },
  },

  mounted() {
    this.onSlideChange();
  },
  created() {
    // TODO: getGroupedVariables()
    this.testimonials = this.groupedVariables.testimonials;
  },
  methods: {
    openVideo(videoUrl: string) {
      this.currentVideoUrl = videoUrl;
      this.isVisible = true;
    },

    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>
