<template>
  <section class="n-section-primary" :style="`background-color: var(--c-${vars.backgroundColor})`">
    <div class="tw-w-full">
      <div>
        <library-title :text="vars.mapTitleText" tag="h2" class="tw-mb-10 tw-text-center"></library-title>

        <neuron-google-map-v2
          v-if="locations && locations.length"
          v-model:markers="locations"
          :marker-color="markerColor"
          :center="locations[0]"
          :markerImg="locations[0]?.marker"
          style="border-radius: var(--c-rounded); overflow: hidden"
        ></neuron-google-map-v2>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import NeuronGoogleMapV2 from '~/components/common/NeuronGoogleMapV2.vue';

export default defineNuxtComponent({
  name: 'Theme5BranchMapAndContactForm',
  components: { NeuronGoogleMapV2 },
  mixins: [ComponentMixin],

  data() {
    return {
      locations: [],
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
      form: null,
    };
  },

  created() {
    this.initMapLocations();
    this.form = this.parseFormV2(this.vars.contactForm);
  },

  computed: {
    markerColor() {
      return this.colors.purple;
    },
    variant() {
      return useVariant(this.component);
    },
  },

  methods: {
    initMapLocations() {
      const branches = this.groupedVariables?.branches || [];
      if (branches.length > 0) {
        this.locations = branches.map((branch: any, index: number) => ({
          id: `branch-${index}-${branch.id || ''}`,
          branch_id: branch.id,
          lat: branch.lat,
          lng: branch.long,
          marker: branch.marker || this.vars?.locationMarkerImage,
          version: 'v3',
          branchName: branch.name,
          color: branch.marker || 'purple',
        }));
      } else {
        this.locations = [
          {
            id: 'single-location',
            branch_id: this.vars.branchId,
            lat: this.vars.locationLatNumber,
            lng: this.vars.locationLngNumber,
            marker: this.vars.locationMarkerImage,
            version: this.vars.mapVersionText,
            branchName: this.vars.branchNameText,
          },
        ];
      }
    },
  },
});
</script>
