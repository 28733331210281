<template>
  <section
    v-if="variants.v0 === variant"
    class="section-services-content pt-5"
    :style="`background-color: var(--white)`"
  >
    <div class="">
      <div class="w-100">
        <div class="padding-bottom padding-huge">
          <div class="d-flex flex-row justify-content-center w-100 pb-5">
            <h2 class="heading-medium">{{ vars.titleText }}</h2>
          </div>
          <Swiper
            :modules="[SwiperNavigation]"
            :slides-per-view="1"
            :initial-slide="1"
            :loop="true"
            effect="fade"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :centered-slides="true"
            :breakpoints="{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1080: {
                slidesPerView: 2,
                spaceBetween: 100,
              },
              1336: {
                slidesPerView: 3,
                spaceBetween: 100,
              },
            }"
            class="slider"
            @swiper="onSwiper"
          >
            <SwiperSlide
              v-for="(item, index) in testimonials"
              :key="`testimonial-${index}`"
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <span class="d-flex gap-3 tw-mb-5"
                ><svg
                  v-for="(i, key) in item.rating"
                  :key="`rating-${key}`"
                  width="24"
                  height="24"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.7375 33L11.175 22.4625L3 15.375L13.8 14.4375L18 4.5L22.2 14.4375L33 15.375L24.825 22.4625L27.2625 33L18 27.4125L8.7375 33Z"
                    fill="#AF624E"
                  />
                </svg>
              </span>
              <p class="text-center tw-text-xl lg:tw-text-3xl" style="max-width: 680px; min-height: 130px">
                {{ item.testimonial }}
              </p>
              <p
                style="
                  text-transform: uppercase;
                  font-size: 18px;
                  margin-top: 20px;
                  letter-spacing: 5px;
                  font-weight: 300;
                "
              >
                {{ item.author }}
              </p>
            </SwiperSlide>
            <div class="controls">
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
                class="slide-left-arrow w-slider-arrow-left"
                @click="() => swiper.slidePrev()"
              >
                <img
                  src="/cdn/theme2/images/left-arrow.svg"
                  loading="lazy"
                  alt="Left Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle slider-circle-transform"></div>
              </div>
              <div
                data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
                class="slide-right-arrow w-slider-arrow-right"
                @click="() => swiper.slideNext()"
              >
                <img
                  src="/cdn/theme2/images/right-arrow.svg"
                  loading="lazy"
                  alt="Right Arrow - Inner Webflow Template"
                  class="arrow-icon slider-arrow-transform"
                />
                <div class="slide-circle right"></div>
              </div>
              <div class="slide-nav w-slider-nav w-round"></div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary variant2-swiper"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="w-100">
      <div class="testimonial-main-heading tw-mb-12">
        <h1 :style="`color: var(--c-${vars.titleColor})`">{{ vars.titleText }}</h1>
      </div>

      <Swiper
        :modules="[SwiperNavigation]"
        :initial-slide="0"
        :loop="false"
        :centered-slides="false"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1080: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }"
        class="slider"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        ref="swiper"
      >
        <SwiperSlide
          v-if="reviews"
          v-for="(review, index) in reviews"
          :key="`testimonial-${index}`"
          class="testimonial-card"
        >
          <div class="testimonial-header mb-4 tw-flex tw-items-center tw-gap-3">
            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="56" height="56" rx="28" fill="#F7F7F9" />
              <g clip-path="url(#clip0_8_876)">
                <path
                  d="M24.3606 16.7894C21.9626 17.6213 19.8946 19.2002 18.4603 21.2943C17.026 23.3884 16.301 25.8872 16.3919 28.4237C16.4827 30.9603 17.3846 33.4008 18.965 35.3869C20.5454 37.373 22.7211 38.8 25.1724 39.4582C27.1598 39.971 29.2419 39.9935 31.2399 39.5238C33.0499 39.1173 34.7233 38.2476 36.0962 37.0001C37.5251 35.662 38.5622 33.9597 39.0962 32.0763C39.6765 30.0282 39.7798 27.8743 39.3981 25.7801H28.2381V30.4094H34.7012C34.572 31.1478 34.2952 31.8525 33.8873 32.4814C33.4795 33.1102 32.9489 33.6504 32.3274 34.0694C31.5382 34.5915 30.6485 34.9428 29.7156 35.1007C28.7798 35.2747 27.82 35.2747 26.8843 35.1007C25.9359 34.9046 25.0387 34.5132 24.2499 33.9513C22.9827 33.0543 22.0312 31.7799 21.5312 30.3101C21.0227 28.8127 21.0227 27.1893 21.5312 25.6919C21.8871 24.6423 22.4755 23.6867 23.2524 22.8963C24.1415 21.9752 25.2672 21.3168 26.5058 20.9933C27.7445 20.6698 29.0484 20.6938 30.2743 21.0626C31.232 21.3565 32.1078 21.8702 32.8318 22.5626C33.5606 21.8376 34.2881 21.1107 35.0143 20.3819C35.3893 19.9901 35.7981 19.6169 36.1674 19.2157C35.0622 18.1872 33.765 17.3869 32.3499 16.8607C29.7731 15.925 26.9536 15.8999 24.3606 16.7894Z"
                  fill="white"
                />
                <path
                  d="M24.3606 16.7894C26.9536 15.8992 29.7731 15.9237 32.3501 16.8587C33.7654 17.3885 35.062 18.1927 36.1657 19.225C35.7907 19.6262 35.3951 20.0012 35.0126 20.3912C34.2851 21.1175 33.5582 21.8412 32.832 22.5625C32.1079 21.8701 31.2321 21.3565 30.2745 21.0625C29.0489 20.6924 27.7451 20.6671 26.5061 20.9893C25.2671 21.3114 24.1408 21.9686 23.2507 22.8887C22.4738 23.6791 21.8854 24.6348 21.5295 25.6844L17.6426 22.675C19.0338 19.916 21.4427 17.8057 24.3606 16.7894Z"
                  fill="#E33629"
                />
                <path
                  d="M16.6114 25.6561C16.8203 24.6207 17.1672 23.618 17.6427 22.6748L21.5295 25.6917C21.0211 27.1891 21.0211 28.8124 21.5295 30.3098C20.2345 31.3098 18.9389 32.3148 17.6427 33.3248C16.4523 30.9554 16.0893 28.2557 16.6114 25.6561Z"
                  fill="#F8BD00"
                />
                <path
                  d="M28.2381 25.7783H39.3981C39.7799 27.8726 39.6766 30.0264 39.0963 32.0746C38.5623 33.958 37.5252 35.6602 36.0963 36.9983C34.8419 36.0196 33.5819 35.0483 32.3275 34.0696C32.9494 33.6501 33.4802 33.1094 33.8881 32.4798C34.296 31.8503 34.5726 31.1448 34.7013 30.4058H28.2381C28.2363 28.8646 28.2381 27.3214 28.2381 25.7783Z"
                  fill="#587DBD"
                />
                <path
                  d="M17.6406 33.3251C18.9369 32.3251 20.2325 31.3201 21.5275 30.3101C22.0285 31.7804 22.9814 33.0549 24.25 33.9513C25.0413 34.5106 25.9404 34.8988 26.89 35.0913C27.8257 35.2653 28.7855 35.2653 29.7213 35.0913C30.6542 34.9334 31.5439 34.5821 32.3331 34.0601C33.5875 35.0388 34.8475 36.0101 36.1019 36.9888C34.7292 38.237 33.0558 39.1073 31.2456 39.5144C29.2476 39.9841 27.1655 39.9616 25.1781 39.4488C23.6063 39.0291 22.1381 38.2893 20.8656 37.2757C19.5187 36.2063 18.4187 34.8588 17.6406 33.3251Z"
                  fill="#319F43"
                />
              </g>
              <defs>
                <clipPath id="clip0_8_876">
                  <rect width="24" height="24" fill="white" transform="translate(16 16)" />
                </clipPath>
              </defs>
            </svg>
            <div class="testimonial-stars">
              <span class="stars-wrapper tw-flex tw-gap-2">
                <svg
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-for="n in review.evaluation_value"
                  :key="`rating-${key}`"
                >
                  <path
                    d="M3.825 19L5.45 11.975L0 7.25L7.2 6.625L10 0L12.8 6.625L20 7.25L14.55 11.975L16.175 19L10 15.275L3.825 19Z"
                    fill="var(--c-secondary)"
                  />
                </svg>
              </span>
            </div>
          </div>

          <h4 :style="`color: var(--c-${vars.titleColor})`" class="mb-2">
            {{ review.person_name }}
          </h4>

          <p class="testimonial-text line-clamp-3 min-h-[100px] mb-2">
            {{ review.content }}
          </p>
        </SwiperSlide>

        <div class="tw-mt-10">
          <LibrarySliderPagination
            :swiper="swiper"
            variant="v7"
            :can-paginate-back="canPaginateBack"
            :can-paginate-next="canPaginateNext"
          />
          <div class="slide-nav w-slider-nav w-round"></div>
        </div>
      </Swiper>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v0: '0',
        v2: 'v2',
      },
      swiper: {},
      testimonials: [] as { [key: string]: string | number | null }[],
      reviews: null,
      canPaginateBack: false,
      canPaginateNext: true,
    };
  },

  created() {
    // TODO: getGroupedVariables()
    this.testimonials = this.groupedVariables.testimonials;
    if (this.variant === this.variants.v2) {
      const parsedReviews = this.parseFormV2(this.vars.googleReviews);
      this.reviews = parsedReviews?.reviews;
    }
  },

  mounted() {
    this.onSlideChange();
  },

  methods: {
    onSwiper(swiper: object) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
.office-detail__label {
  font-weight: 300;
  color: var(--brown--secondary);
  font-size: 0.8rem;
  line-height: 14.35px;
  letter-spacing: 5px;
  text-transform: uppercase;
}
.office-detail__value {
  font-weight: 300;
  color: var(--dark-brown);
  font-size: 1.3rem;
  padding-right: 4rem;
}
.w-min-content {
  width: min-content;
}

.office-detail-title {
  width: min-content;
  font-size: 6.25rem;
}

.social-media {
  background: var(--dark-brown);
  width: 36px;
  height: 36px;
  flex-shrink: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.controls {
  height: 80px;
  margin-top: 20px;
  text-align: center;
}

.swiper-slide {
  opacity: 0.5;
}
.swiper-slide-active {
  opacity: 1;
}

.slide-left-arrow,
.slide-right-arrow {
  bottom: unset;
}

.variant2-swiper :deep(.swiper-slide) {
  opacity: 1 !important;
}
.variant2-swiper :deep(.swiper-slide-active) {
  opacity: 1 !important;
}
</style>
