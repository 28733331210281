<template>
  <article
    v-if="blog && variants.v1 === variant"
    class="nc-text mt-3"
    :style="`background-color: var(--${backgroundColor}) !important;`"
  >
    <div class="container-medium padding-top padding-huge">
      <div class="padding-vertical padding-large">
        <h1 class="heading-small" style="color: var(--dark-brown); font-weight: 300">
          {{ blog.title }}
        </h1>
        <div v-if="blog.description" class="empty-state w-dyn-empty mt-5" style="padding: 20px; color: var(--black)">
          <div>
            {{ blog.description }}
          </div>
        </div>
        <div class="margin-top margin-large">
          <library-image v-if="blog.media_type === 'image'" :src="blog.largeImage"> </library-image>
          <library-iframe v-else-if="blog.media_type === 'video'" :src="blog.video_link"> </library-iframe>
        </div>
      </div>
    </div>
    <div class="container-medium">
      <div class="padding-vertical padding-medium">
        <div class="text-rich-text w-richtext" style="color: var(--black)" v-html="blog.content"></div>
      </div>
      <div class="d-md-none divider-line"></div>
    </div>
  </article>

  <section
    v-if="variants.v2 === variant && blog"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-items-center tw-self-start tw-px-4 tw-pb-6 tw-pt-20">
      <nuxt-link
        to="/properties"
        :external="true"
        class="n-tertiary tw-mb-10 tw-flex tw-items-center tw-gap-6 tw-self-start !tw-p-0 tw-text-[var(--c-secondary)]"
      >
        <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.07545 7.91476L4.37536 11.2147L5.2002 10.3898L2.3127 7.50234L5.2002 4.61484L4.37536 3.79001L1.07545 7.08993C0.966087 7.19932 0.904654 7.34766 0.904654 7.50234C0.904654 7.65702 0.966087 7.80537 1.07545 7.91476Z"
            fill="#F46F2C"
          />
        </svg>

        BACK TO ALL BLOGS
      </nuxt-link>
    </div>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <article
        class="tw-col-span-12 tw-h-fit tw-p-4 md:tw-p-6 lg:tw-col-span-7 xl:tw-col-span-8"
        style="border-radius: var(--rounded); background-color: var(--c-section_tertiary)"
      >
        <h2 class="tw-mb-10" style="color: var(--c-primary)">{{ blog.title }}</h2>
        <div class="tw-w-full">
          <NuxtImg
            v-if="blog.media_type === 'image'"
            :src="blog.largeImage"
            loading="lazy"
            :alt="blog.title"
            class="tw-w-full"
            :title="blog.title"
            style="border-radius: var(--rounded)"
            sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, (max-width: 1439px) 92vw, 1280px"
            :srcset="`${blog.largeImage} 500w,
                ${blog.largeImage} 800w,
                ${blog.largeImage} 1080w,
               ${blog.largeImage} 1600w,
                ${blog.largeImage} 2500w`"
          />
          <library-iframe v-else-if="blog.media_type === 'video'" :src="blog.video_link"> </library-iframe>
        </div>

        <div
          v-if="blog.description"
          style="
            padding: 20px;
            color: var(--c-primary);
            background-color: var(--c-section_primary);
            border-radius: var(--rounded);
          "
          class="tw-my-10"
        >
          <h5>{{ blog.description }}</h5>
        </div>
        <div>
          <lazy-library-text :html="blog.content"></lazy-library-text>
        </div>

        <div
          v-if="vars.agentCardBool"
          class="tw-mt-10 tw-flex tw-flex-col tw-items-center tw-justify-between tw-gap-4 tw-p-7 md:tw-flex-row md:tw-p-10"
          style="background-color: var(--c-section_primary); border-radius: var(--rounded)"
        >
          <div class="tw-flex tw-w-full tw-flex-nowrap tw-items-center tw-gap-6 md:tw-w-[40%]">
            <NuxtImg :src="vars.agentCardPhotoImage" style="border-radius: 9999px" class="tw-h-20 tw-w-20" />
            <h5 style="color: var(--c-primary)" class="tw-w-full">{{ vars.agentNameText }}</h5>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col tw-items-start tw-gap-5 md:tw-w-[60%]">
            <p>{{ vars.agentDescriptionText }}</p>
            <nuxt-link
              v-if="vars.agentButtonLinkUrl"
              :to="`${vars.agentButtonLinkUrl}`"
              :class="`n-${vars.agentButtonTypeText}`"
            >
              {{ vars.agentButtonLabelText }}
              <span class="btn-arrow"
                ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                    fill="white"
                  />
                </svg>
              </span>
            </nuxt-link>
          </div>
        </div>
      </article>

      <div class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4">
        <template v-if="sidebarComponents && sidebarComponents.length">
          <div class="sidebar tw-flex tw-flex-col tw-gap-6">
            <available-components-for-sidebar
              key="blog-detail-sidebar"
              key-prefix="blog-detail-sidebar"
              :specific-components="sidebarComponents"
            />
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useCoreStore } from '~/units/core/store';
import { useDataFromState } from '~/composables';
import { useBlogsStore } from '~/units/blogs/store';
import { ROUTES } from '~/constants/ui.constants';
import AvailableComponentsForSidebar from '~/components/theme2/AvailableComponentsForSidebar.vue';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme2BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  components: { AvailableComponentsForSidebar },

  data() {
    return {
      variants: {
        v1: 'BlogDetail1',
        v2: 'v2',
      },
    };
  },

  async setup(props: { component: Component }) {
    const route = useRoute();
    const slug = route.params.blog_id as string;

    if (slug) {
      await fetchBlogManager(props.component, slug);

      const { data } = useDataFromState('singleBlogRepo');

      const blogsStore = useBlogsStore();
      await blogsStore.fetchSingleBlog(props.component);

      if (data && data[0] && data[0].data && data[0].data.article && data[0].data.article.title) {
        const coreStore = useCoreStore();
        const title = data[0].data.article.title;
        const introductionText = data[0].data.article.introduction_text;

        coreStore.updateMetaTagsGroup({
          title,
          seo_meta_title: title,
          og_meta_title: title,
          seo_meta_description: introductionText || '',
          og_meta_description: introductionText || '',
          og_meta_image: `https://lifesycle-cdn.s3.eu-west-2.amazonaws.com/${data[0].data.article.image.large}`,
        });
      }
    }
  },

  created() {
    this.form = this.parseFormV2(this.vars.blogForm);

    if (process.server && !this.blog) {
      const route = useRoute();

      this.redirectTo({
        path: '/not-found',
        query: {
          path: route.path,
        },
      });
    }
  },

  computed: {
    backgroundColor() {
      return getVariable(this.component, 'background-color');
    },

    sidebarComponents(): Component[] {
      if (this.currentPage && this.currentPage.sidebar) {
        return this.currentPage.sidebar;
      }
      return [];
    },

    ROUTES() {
      return ROUTES;
    },

    ...mapState(useWebsiteStore, ['sidebarComponents']),
  },
});
</script>

<style scoped></style>
