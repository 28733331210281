<template>
  <div v-if="variants.NeilKing === variant" class="section-product-header" style="background-color: var(--white)">
    <lazy-theme2-base-not-found :is-full-screen-error="true" :title="vars.titleText">
      <template #description>
        <div style="color: var(--brown--secondary)" class="tw-mt-10 tw-text-2xl">
          {{ vars.descriptionText }}
        </div>
        <div class="tw-mt-5 tw-flex tw-flex-row tw-justify-center">
          <lazy-common-inputs-nc-button
            :label="vars.buttonLabelText"
            :is-link="true"
            link="/"
          ></lazy-common-inputs-nc-button>
        </div>
      </template>
    </lazy-theme2-base-not-found>
  </div>

  <lazy-theme2-base-not-found
    v-else-if="variants.v2 === variant"
    version="v2"
    class="tw-flex tw-items-center tw-justify-center tw-text-center"
    :is-full-screen-error="true"
    :background-color="vars.backgroundColor"
  >
    <template #title>
      <div class="tw-flex tw-flex-col tw-items-center tw-justify-center lg:tw-gap-6">
        <div
          class="tw-flex tw-w-full tw-max-w-[1100px] tw-flex-col tw-items-center tw-justify-center tw-gap-10 tw-text-center"
        >
          <h1 :style="`color: var(--c-${vars.titleColor})`">{{ vars.titleText }}</h1>
          <nuxt-link to="/" :class="`n-primary`"
            >{{ vars.buttonLabelText }}
            <span class="btn-arrow"
              ><svg width="9" height="15" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.92455 7.41479L2.62464 10.7147L1.7998 9.88987L4.6873 7.00237L1.7998 4.11487L2.62464 3.29004L5.92455 6.58996C6.03391 6.69935 6.09535 6.84769 6.09535 7.00237C6.09535 7.15705 6.03391 7.3054 5.92455 7.41479Z"
                  fill="white"
                />
              </svg> </span
          ></nuxt-link>
        </div>
      </div>
    </template>
  </lazy-theme2-base-not-found>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'NotFoundPage',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        NeilKing: 'NeilKing',
        v2: 'v2',
      },
    };
  },

  setup() {
    const event = useRequestEvent();

    // event will be undefined in the browser
    if (event) {
      // Set the status message as well
      setResponseStatus(event, 404, 'Page Not Found');
    }
  },
});
</script>
