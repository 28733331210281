<template>
  <section
    v-if="variants.v1 === variant"
    class="section-services-content pt-5"
    :style="`background-color: var(--white)`"
  >
    <div class="page-padding">
      <div class="container-large">
        <div class="padding-bottom padding-huge">
          <div class="about-accordion w-layout-grid service-component-wrapper">
            <div style="align-self: center">
              <h2 class="heading-medium" style="margin-bottom: 3rem">
                {{ vars.titleText }}
              </h2>
              <div class="margin-top margin-small">
                <div class="">
                  <div
                    v-for="(accordion, index) in accordionElements"
                    :key="index"
                    class="border-bottom"
                    @click="toggleAccordion(index)"
                  >
                    <h2 class="d-flex justify-content-between align-items-center font-weight-bold heading-xsmall">
                      {{ accordion.title }}
                      <svg
                        v-if="activeIndex === index"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M5 12h14"></path>
                        <path d="M12 5v14"></path>
                      </svg>
                    </h2>
                    <transition
                      name="accordion"
                      @enter="startTransition"
                      @leave="endTransition"
                      @after-leave="onAfterLeave"
                    >
                      <p v-show="activeIndex === index">
                        {{ accordion.description }}
                      </p>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-_67e6759a-e0fe-7f50-6614-460154e4a2c7-89606db0"
              class="service-details-content position-relative"
            >
              <div class="margin-top margin-small">
                <div class="max-width-full about-img">
                  <img :src="vars.aboutImage" loading="lazy" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section v-if="variants.v2 === variant" class="n-section-primary">
    <div class="col-12">
      <div class="w-layout-grid service-component-wrapper d-flex flex-column flex-lg-row !tw-mb-0 tw-w-full">
        <div class="order-2 order-lg-1 col-lg-6">
          <h1 class="heading-medium" style="margin-bottom: 3rem">
            {{ vars.titleText }}
          </h1>
          <div>
            <div>
              <div
                v-for="(accordion, index) in accordionElements"
                :key="index"
                class="accordion-item mb-3 tw-cursor-pointer"
                @click="toggleAccordion(index)"
              >
                <h4 class="d-flex justify-content-between align-items-center font-weight-bold heading-xsmall">
                  {{ accordion.title }}
                  <svg
                    v-if="activeIndex === index"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14"></path>
                    <path d="M12 5v14"></path>
                  </svg>
                </h4>
                <transition
                  name="accordion"
                  @enter="startTransition"
                  @leave="endTransition"
                  @after-leave="onAfterLeave"
                >
                  <p v-show="activeIndex === index" class="mt-2">
                    {{ accordion.description }}
                  </p>
                </transition>
              </div>
            </div>
          </div>
        </div>

        <div class="service-details-content position-relative order-1 order-lg-2 mb-4 mb-lg-0 tw-w-full lg:tw-pl-12">
          <img
            :src="vars.aboutImage"
            style="width: 100%; max-width: 900px; height: auto; object-fit: cover; border-radius: var(--rounded)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
export default defineNuxtComponent({
  name: 'AboutAccordion',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: '0',
        v2: 'v2',
      },
      activeIndex: 0,
      accordionElements: [] as { [key: string]: string | number | null }[],
    };
  },

  created() {
    this.accordionElements = this.groupedVariables.accordion;
  },

  methods: {
    toggleAccordion(index: number) {
      if (this.activeIndex === index) {
        this.activeIndex = -1;
      } else {
        this.activeIndex = index;
      }
    },
    startTransition(el: any) {
      el.style.height = '0';
      el.style.opacity = '0';
      setTimeout(() => {
        el.style.height = el.scrollHeight + 'px';
        el.style.opacity = '1';
      }, 10);
    },
    endTransition(el: any) {
      el.style.height = el.scrollHeight + 'px';
      setTimeout(() => {
        el.style.height = '0';
        el.style.opacity = '0';
      }, 10);
    },
    onAfterLeave(el: any) {
      el.style.display = 'list-item';
    },
  },
});
</script>
<style scoped>
.border-bottom {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  user-select: none;
}

.service-details-content {
  /* grid-area: unset !important; */
  align-self: flex-start !important;
  position: sticky;
  top: 0;
}

.accordion-enter-active,
.accordion-leave-active {
  transition:
    height 0.4s ease-in-out,
    opacity 0.4s ease-in-out;
}
.accordion-enter,
.accordion-leave-to {
  height: 0;
  opacity: 0;
}

.border-bottom:last-child {
  border-bottom: 0px !important;
}

.about-accordion {
  grid-template-columns: 1fr 5rem 1fr;
}

.about-accordionv2 {
  grid-template-columns: 1fr 4rem 5fr;
}

@media only screen and (max-width: 991px) {
  .about-accordion {
    grid-template-columns: auto 1rem 1fr;
  }
}

@media only screen and (min-width: 990px) {
  .about-img {
    position: absolute;
    top: 150px;
  }
}
</style>
